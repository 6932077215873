import React from 'react'

import * as DefaultStyles from './default.module.scss'

export const StyleContext = React.createContext({ styles: DefaultStyles })

export const StyleProvider = ({ children, styles }) => (
  <StyleContext.Provider value={{ styles }}>{children} </StyleContext.Provider>
)

export const StyleConsumer = ({ children, styles: overwriteStyles }) => {
  if (overwriteStyles) {
    return <>{children({ styles: overwriteStyles })}</>
  }

  return (
    <StyleContext.Consumer>
      {({ styles }) => <>{children({ styles })}</>}
    </StyleContext.Consumer>
  )
}
