import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'
import { Provider as ReferenceProvider } from './Reference'

export const Base = ({ plans, children, filter = () => true }) => {
  return plans.filter(filter).map((v) => (
    <ReferenceProvider plan={v} key={v.id}>
      {children(v)}
    </ReferenceProvider>
  ))
}

export const Each = connect((state) => ({
  plans: selectors.selectEntries(state),
}))(Base)
