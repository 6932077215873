import React from 'react'

import * as BaseLayout from '../KeyValueLayout'

import { StyleConsumer } from './StyleContext'

export * from './StyleContext'

/**
 */
export const Field = ({ className, children }) => (
  <StyleConsumer>
    {(styles) => (
      <BaseLayout.Pair className={className ? className : styles.Field}>
        {children}
      </BaseLayout.Pair>
    )}
  </StyleConsumer>
)

/**
 */
export const Label = ({ children, className }) => (
  <StyleConsumer>
    {(styles) => (
      <BaseLayout.Key className={className ? className : styles.Label}>
        {children}
      </BaseLayout.Key>
    )}
  </StyleConsumer>
)

/**
 */
export const Input = ({ children, className }) => (
  <StyleConsumer>
    {(styles) => (
      <BaseLayout.Value className={className ? className : styles.Input}>
        {children}
      </BaseLayout.Value>
    )}
  </StyleConsumer>
)
