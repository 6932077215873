import React from 'react'

import * as OptionGroup from 'modules/room/components/Reference/OptionGroups/Reference'
import * as Color from 'modules/color_selection/components/Reference'

import { RoomOption } from './RoomOption'

/**
 */
export const RoomOptions = () => {
  const { colorSelection } = React.useContext(Color.Context)

  return (
    <OptionGroup.Options.Each filterByColorSelection={colorSelection.id}>
      {(option) => <RoomOption />}
    </OptionGroup.Options.Each>
  )
}
