// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--1-M5g";
export var FrontContainer = "styles-module--FrontContainer--1QhV2";
export var MessageContainer = "styles-module--MessageContainer--2Qe93";
export var SideMenu = "styles-module--SideMenu--1s6Sw";
export var InfoContainer = "styles-module--InfoContainer--hM4xA";
export var SideMenu_Inner = "styles-module--SideMenu_Inner--3ZIVd";
export var SideMenu_Toggle = "styles-module--SideMenu_Toggle--1pUH6";
export var SideMenu_Toggle_Icon = "styles-module--SideMenu_Toggle_Icon--3TEDc";
export var SideMenu_Toggle_Icon_Image = "styles-module--SideMenu_Toggle_Icon_Image--37Iot";
export var SideMenu_Content = "styles-module--SideMenu_Content--wixms";
export var SideMenu_Content_Wrapper = "styles-module--SideMenu_Content_Wrapper--2w9iP";