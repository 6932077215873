import React from 'react'

import * as Modal from 'atoms/Modal'
import * as styles from './styles.module.scss'
import * as AddPlanForm from 'components/AddPlanForm'

/**
 *
 */
const CloseButton = () => {
  const { closeModal } = React.useContext(Modal.Context)
  return (
    <button
      type="button"
      onClick={() => {
        closeModal()
      }}
      className={styles.CloseButton}
    >
    </button>
  )
}

const Form = () => {
  const { closeModal } = React.useContext(Modal.Context)
  return (
    <AddPlanForm.Container
      onSuccess={() => {
        closeModal()
      }}
    >
      <div>
        <div className={styles.Form_Content}>
          <div className={styles.Form_Content_Left}>
            <AddPlanForm.FormFields />
            <AddPlanForm.Submit className={styles.Submit}>
              お部屋を追加する
              <div className={styles.Submit_Icon} />
            </AddPlanForm.Submit>
          </div>
          <div className={styles.Form_Content_Right}>
            <AddPlanForm.Preview />
          </div>
        </div>

      </div>
    </AddPlanForm.Container>
  )
}

/**
 */
export const Content = () => (
  <Modal.Content>
    <div className={styles.Container}>
      <Form />
      <CloseButton />
    </div>
  </Modal.Content>
)
