import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'
import { Provider as ReferenceProvider } from './Reference'

import { filterByPropertyType } from '../utils'

export const Base = ({
  properties,
  propertyType,
  children,
  filter = () => true,
}) => {
  return properties.filter(filterByPropertyType(propertyType)).map((v) => (
    <ReferenceProvider property={v} key={v.key}>
      {children(v)}
    </ReferenceProvider>
  ))
}

export const Each = connect((state) => ({
  properties: selectors.selectEntries(state),
}))(Base)
