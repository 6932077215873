import React from 'react'
//
import { connect } from 'react-redux'
//
import { FormName, isPristine, isSubmitting, getFormValues} from 'redux-form'
import { StyleConsumer } from './StyleContext'

/**
 *
 */
const Connected = connect(
  //mapStateToProps
  (state, { form }) => ({
    values: getFormValues(form)(state),
    pristine: isPristine(form)(state),
    submitting: isSubmitting(form)(state),
  }),
)(
  //BaseComponent
  ({
    className,
    styles: overwriteStyles,
    pristine,
    submitting,
    children = 'submit',
    dispatch,
    values,
    ...props
  }) => {
    return (
    <StyleConsumer sytles={overwriteStyles}>
      {({ styles }) => (
        <button
          type="submit"
          disabled={submitting || !values || !values.colorSelection  }
          className={className ? className : styles.Submit}
          {...props}
        >
          {children}
        </button>
      )}
    </StyleConsumer>
  )
  },
)

/**
 *
 */
export const Submit = ({ children, ...props }) => (
  <FormName>
    {({ form }) => (
      <Connected form={form} {...props}>
        {children}
      </Connected>
    )}
  </FormName>
)
