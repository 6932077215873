import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../actions'
import * as selectors from '../selectors'

/**
 */
const DefaultLoading = () => <span>Loading</span>

/**
 * Initializer for Redux
 */
const ReduxInitializer = connect(
  // mapStateToProps
  (state) => ({
    isInitialized: selectors.selectIsInitialized(state),
  }),
  // mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        init: actions.init,
      },
      dispatch,
    ),
)(
  // BaseComponent
  class extends React.Component {
    componentDidMount = () => {
      const { init, data, isInitialized } = this.props

      if (!isInitialized) {
        // dispatch init
        init(data.properties.nodes)
      }
    }

    render = () => {
      const {
        children,
        isInitialized,
        renderers: { Loading = DefaultLoading } = {},
      } = this.props

      if (!isInitialized) {
        return <Loading />
      }

      return children
    }
  },
)

/**
 * Connected Initializer
 */
export const Initializer = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query PropertiesInitializerQuery {
      properties: allPropertiesYaml(sort: { fields: name }) {
        nodes {
          key
          name
          propertyType
          cost
          sideViewImage
        }
      }
    }
  `)

  return (
    <ReduxInitializer data={data} {...props}>
      {children}
    </ReduxInitializer>
  )
}
