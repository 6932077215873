import React from 'react'

import * as styles from './styles.module.scss'

const DefaultMessage = '読み込み中...'

/**
 * Inline Loading
 */
export const Inline = ({ children }) => (
  <div className={styles.Inline}>
    <span className={styles.Message}>
      {children ? children : DefaultMessage}
    </span>
  </div>
)

/**
 * Page Loading
 */
export const Page = ({ children }) => (
  <div className={styles.Page}>
    <span className={styles.Message}>
      {children ? children : DefaultMessage}
    </span>
  </div>
)
