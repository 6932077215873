import * as consts from './consts'

/**
 */
export const actionName = (name) => `${consts.ACTION_PREFIX}${name}`

/**
 */
export const info = require('debug')('module:room_plan:info')

/**
 */
export const filterByPropertyType = (propertyType) => (v) => {
  if (propertyType) {
    return v.propertyType === propertyType
  }
  return true
}
