import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

export const {
  init: { request: init, success: initSuccess, failure: initFailure },
} = createActions(
  {
    INIT: {
      REQUEST: (entries) => ({ entries }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
  },
  {
    prefix: ACTION_PREFIX,
  },
)
