import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../../selectors'
import { Context } from './Context'

/**
 * Reference Provider
 */
export const Provider = connect(
  //
  (state, props) => ({
    colorSelection: props.colorSelection ?? selectors.selectEntryById(state)(props.id)
  }),
)(
  // Base
  ({ colorSelection, ...props }) => (
    <Context.Provider {...props} value={{ colorSelection }} />
  ),
)
