import { fork, all } from 'redux-saga/effects'

import { sagas as planSagas } from './plan/sagas'
import { sagas as propertyTypeSagas } from './property_type/sagas'
import { sagas as propertySagas } from './property/sagas'
import { sagas as roomPlanSagas } from './room_plan/sagas'
import { sagas as roomSagas } from './room/sagas'
import { sagas as colorSelectionSagas } from './color_selection/sagas'
import { sagas as optionSagas } from './option/sagas'

export function* sagas() {
  yield all([
    fork(propertySagas),
    fork(propertyTypeSagas),
    fork(roomPlanSagas),
    fork(planSagas),
    fork(roomSagas),
    fork(colorSelectionSagas),
    fork(optionSagas),
  ])
}
