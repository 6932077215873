import React from 'react'

import { Context as PlanContext } from '../Context'
import { Provider as OptionValueProvider } from 'modules/option/components/Value/Provider'

/**
 * Get an OptionValue for current selected plan
 */
export const One = ({
  children,
  id,
  renderers: { Empty = () => null } = {},
}) => {
  const {
    plan: { roomOptionValues },
  } = React.useContext(PlanContext)

  return (
    <OptionValueProvider value={roomOptionValues[id].value}>
      {children(roomOptionValues[id].value)}
    </OptionValueProvider>
  )
}
