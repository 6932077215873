import React from 'react'

import { Context } from '../Context'

const DefaultRender = () => null

const hasProperty = (selector) => ({
  renderFalse: RenderFalse = DefaultRender, 
  renderTrue: RenderTrue = DefaultRender,
  children,
}) => {
  const { value } = React.useContext(Context)

  const property = selector(value)

  if(!property) {
    return <RenderFalse/>
  }

  if(children) {
    return children
  }

  return <RenderTrue/>
}

export const HasCode = hasProperty((value) => value.productCode)

export const HasURL = hasProperty((value) => value.url)

