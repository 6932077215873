import React from 'react'

import { Context } from './Context'
import * as ImageHelper from 'utils/ImageHelper'

/**
 */
const DefaultTextRenderer = ({ value, ...props }) => (
  <span {...props}>{value}</span>
)

/**
 */
const DefaultImageRenderer = ({ src, children, ...props }) => (
  <img src={src} {...props} />
)

const DefaultLoading = () => <>Loading</>

const withProperty =
  (selector) =>
  (Component) =>
  //
  ({ components: { Loading = DefaultLoading } = {}, ...props }) => {
    const { isLoaded, room } = React.useContext(Context)

    if (!isLoaded) {
      return <Loading />
    }

    if (!room) {
      return null
    }

    return <Component value={selector(room)} {...props} />
  }

const Consumer = ({ children }) => (
  <Context.Consumer>
    {({ isLoaded, room }) => {
      if (!isLoaded) {
        return false
      }

      if (!room) {
        throw new Error('Room is not initialized')
      }
      return <>{children(room)}</>
    }}
  </Context.Consumer>
)

/**
 *
 */
export const Id = ({
  render: Renderer = DefaultTextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ id: value = defaultValue }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const Label = ({
  render: Renderer = DefaultTextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ label: value = defaultValue }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const VRImage = ({
  render: Renderer = DefaultImageRenderer,
  default: defaultValue = null,
  ...props
}) => (
  <Consumer>
    {({ vr_image: value = defaultValue }) => {
      return <Renderer {...props} src={ImageHelper.resolve(value)} />
    }}
  </Consumer>
)

/**
 */
export const LayoutImage = withProperty((entry) => entry.layout.image)(
  ({ value, render: Renderer = DefaultImageRenderer, ...props }) => {
    const src = ImageHelper.resolve(value)
    const alt = '間取り図'
    return <Renderer {...props} src={src} alt={alt} value={{ src, alt }} />
  },
)
