import React from 'react'

import { connect } from 'react-redux'

import * as selectors from '../../../selectors'
import { Provider as ValueProvider } from '../../Value'
import { Context as OptionContext } from '../Context'

/**
 *
 */
export const Each = ({ children }) => {
  const { option } = React.useContext(OptionContext)

  return option.values.map((value) => (
    <ValueProvider value={value} key={`${value.value}`}>
      {children(value)}
    </ValueProvider>
  ))
}
