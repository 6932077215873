import React from 'react'

import { connect } from 'react-redux'

import * as selectors from '../../selectors'
import { Context } from './Context'

/**
 */
export const Provider = connect((state, props) => ({
  propertyType: props.propertyType
    ? props.propertyType
    : selectors.selectEntryByName(state)(props.name),
}))(
  // Base Component
  ({ propertyType, children }) => (
    <Context.Provider
      value={{
        propertyType,
      }}
    >
      {children}
    </Context.Provider>
  ),
)
