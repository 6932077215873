import { all, put, race, take, takeLatest } from 'redux-saga/effects'
// Module imports
//import { init as selectedInit } from "modules/selected/actions"
// Local imports
import * as actions from './actions'
import { log } from './utils'

/**
 */
function* handleInit(action) {
  try {
    log(action)

    //const [isSuccess, ...failures] = yield race([
    //  all([
    //    // take successes
    //    take('MODULE/SELECTED/INIT/SUCCESS'),
    //    // call init
    //    put(selectedInit()),
    //  ]),
    //  // take failures
    //  take('MODULE/SELECTED/INIT/FAILURE'),
    //])
    //
    //log("initialize complete")
    //
    //if (isSuccess) {
    //  log("init success")
    //  yield put(actions.initSuccess())
    //} else {
    //  console.error(failures)
    //  throw failures[failures.find(f => !!f)]
    //}

    yield put(actions.initSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.initFailure(e))
  }
}

function* handleSetRoomPlan(action) {
  try {
    const { rooms } = action.payload.roomPlan

    const options = rooms.flatMap((room) => {
      return room.optionGroups.flatMap((og) => og.optinos)
    })

    //yield put(resetRoomOptions(values))

    //yield put(initRoomOptions(options))
  } catch (e) {
    console.error(e)
  }
}

export function* sagas() {
  yield all([takeLatest('STORE/INIT/REQUEST', handleInit)])
}
