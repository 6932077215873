import React from 'react'

import { Context } from '../Context'
import * as Renderers from 'atoms/Renderer'

const Consumer = ({ children }) => (
  <Context.Consumer>
    {({ optionGroup }) => children(optionGroup)}
  </Context.Consumer>
)

/**
 */
export const Title = ({
  render: Renderer = Renderers.TextRenderer,
  ...props
}) => (
  <Consumer>
    {({ title: value }) => <Renderer {...props} value={value} />}
  </Consumer>
)
