import React from 'react'

import * as RoomPlan from 'modules/room_plan/components/Reference'
import { Provider as RoomProvider } from 'modules/room/components/Reference'

export const ActiveRoomContext = React.createContext()

/**
 */
export const ActiveRoomProvider = ({ children }) => (
  <RoomPlan.Consumer>
    {({ roomPlan }) => (
      <BaseProvider roomPlan={roomPlan.id} defaultRoom={roomPlan.defaultRoom}>
        {children}
      </BaseProvider>
    )}
  </RoomPlan.Consumer>
)

/**
 */
const BaseProvider = ({ children, defaultRoom, roomPlan, ...props }) => {
  const [selectedRoom, setSelectedRoom] = React.useState(defaultRoom)

  React.useEffect(() => {
    setSelectedRoom(defaultRoom)
  }, [roomPlan])

  return (
    <ActiveRoomContext.Provider value={{ selectedRoom, setSelectedRoom }}>
      <RoomProvider {...props} roomKey={selectedRoom} roomPlan={roomPlan}>
        {children}
      </RoomProvider>
    </ActiveRoomContext.Provider>
  )
}

//export const ActiveRoomConsumer = ({ children }) => (
//  <ActiveRoomContext.Consumer>{(values) => children(values)}</ActiveRoomContext.Consumer>
//)
