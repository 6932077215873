// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--3JJeu";
export var Title = "styles-module--Title--3faLr";
export var AmountsContainer = "styles-module--AmountsContainer--2ESqw";
export var AmountsBorder = "styles-module--AmountsBorder--3X2GW";
export var EstimateButton = "styles-module--EstimateButton--o36A5";
export var EstimateButton_Icon = "styles-module--EstimateButton_Icon--109re";
export var AmountItem = "styles-module--AmountItem--34Rea";
export var TotalAmountItem = "styles-module--TotalAmountItem--oMNlW";
export var AmountItem_Name = "styles-module--AmountItem_Name--2ztmg";
export var TotalAmountItem_Name = "styles-module--TotalAmountItem_Name--3Bkq8";
export var AmountItem_Cost = "styles-module--AmountItem_Cost--lIFoO";
export var TotalAmountItem_Cost = "styles-module--TotalAmountItem_Cost--2nJUv";
export var TotalAmountItem_NameSmall = "styles-module--TotalAmountItem_NameSmall--1BQO0";