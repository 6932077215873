import React from 'react'

import { connect } from 'react-redux'

import * as selectors from '../../selectors'
import { Context } from './Context'

/**
 */
export const Provider = connect((state, props) => ({
  property: props.property
    ? props.property
    : selectors.selectEntryByKey(state)(props.id),
}))(({ property, children }) => (
  <Context.Provider value={{ property }}>{children}</Context.Provider>
))
