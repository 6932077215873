import React from 'react'

import * as PropertyTypeRef from 'modules/property_type/components/Reference'
import * as RoomPlanRef from 'modules/room_plan/components/Reference'

import * as Elements from './Elements'

import * as styles from './styles.module.scss'

import { Consumer as ModalConsumer } from 'atoms/Modal'
import * as LayoutImageModal from 'components/LayoutImageModal'

///**
// *
// */
//const LayoutInfoSection = () => {
//  const { roomPlan } = React.useContext(RoomPlanRef.Context)
//
//
//  return (
//    <ModalConsumer>
//      {({ openModal }) => (
//        <div className={styles.LayoutInfoSection_Container}>
//          <div>
//            <Elements.LayoutText />
//            <a
//              onClick={() =>
//                openModal(
//                  <RoomPlan.Provider roomPlan={roomPlan}>
//                    <LayoutImageModal />
//                  </RoomPlan.Provider>,
//                )
//              }
//            >
//              間取り図を拡大表示
//            </a>
//          </div>
//        </div>
//      )}
//    </ModalConsumer>
//  )
//}

/**
 *
 */
const InfoSection = () => (
  <div className={styles.Property_InfoSection}>
    <LayoutSection />
  </div>
)

/**
 */
const LayoutSection = () => {
  const { roomPlan } = React.useContext(RoomPlanRef.Context)
  const { propertyType } = React.useContext(PropertyTypeRef.Context)

  return (
    <div className={styles.Property_LayoutSection}>
      <div className={styles.Property_LayoutInfo}>
        <Elements.PropertyTypeName />
        <Elements.LayoutText />
        <Elements.OccupiedArea />
        <Elements.BalconyArea />

        <ModalConsumer>
          {({ openModal }) => (
            <RoomPlanRef.Properties.LayoutImage
              render={({ value }) => (
                <a
                  onClick={() =>
                    openModal(
                      <PropertyTypeRef.Provider propertyType={propertyType}>
                        <RoomPlanRef.Provider roomPlan={roomPlan}>
                          <LayoutImageModal.Content
                            image={value.src}
                            alt={value.alt}
                          />
                        </RoomPlanRef.Provider>
                      </PropertyTypeRef.Provider>,
                    )
                  }
                  className={styles.ModalButton}
                >
                  間取り図を拡大表示 &gt;
                </a>
              )}
            />
          )}
        </ModalConsumer>
      </div>
      <RoomPlanRef.Properties.LayoutImage className={styles.Layout_Image} />
    </div>
  )
}

/**
 *
 */
const ImageSection = () => (
  <div className={styles.RoomPlan_ImageSection}>
    <Elements.PropertyImage />
  </div>
)

/**
 *
 */
export const RoomPlan = ({ propertyType, property, roomPlan }) => (
  <div className={styles.RoomPlan_Container}>
    <ImageSection />
    <InfoSection />
  </div>
)
