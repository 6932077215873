import React from 'react'
// module imports
import { Initializer as ModalInitializer } from 'atoms/Modal'
import { DataInitializer } from './DataInitializer'

// style imports
import 'styles/global.scss'
import * as styles from './styles.module.scss'

const Layout = ({ children, pageContext }) => {
  return (
    <DataInitializer>
      <ModalInitializer>
        <div className={styles.Container}>{children}</div>
      </ModalInitializer>
    </DataInitializer>
  )
}

export default Layout
