import React from 'react'

import * as Room from 'modules/room/components/Reference'
import * as RoomPlan from 'modules/room_plan/components/Reference'
import * as Plan from 'modules/plan/components/Reference'

import { vrPath } from 'utils/vrHelper'

import * as styles from './styles.module.scss'

/**
 */
export const Images = () => {
  const { room } = React.useContext(Room.Context)

  const { roomPlan } = React.useContext(RoomPlan.Context)
  const { plan } = React.useContext(Plan.Context)

  const selectedColor = plan.colorSelection.id

  const roomOptionIds = room.optionGroups.map(({ options }) => options).flat()

  const vrOptions = Object.entries(plan.roomOptionValues)
    .filter(([id, { option, value }]) => {
      return (
        option.isSupportVr &&
        roomOptionIds.includes(id) &&
        (!option.colorSelection || option.colorSelection === selectedColor)
      )
    })
    .map(([id, { option, value }]) => {
      console.log(option.vrPrefix, option.vrWeight)
      return [option, value]
    })
    .sort(([aOpt, aValue], [bOpt, bValue]) => {
      const weightDiff = aOpt.vrWeight - bOpt.vrWeight

      if (weightDiff === 0) {
        if (aOpt.vrPrefix < bOpt.vrPrefix) {
          return -1
        } else if (aOpt.vrPrefix > bOpt.vrPrefix) {
          return 1
        } else {
          return 0
        }
      } else {
        return weightDiff
      }
    })
    .map(([option, value]) => {
      console.log('a', option.vrPrefix)
  
      return [option.vrPrefix, value.vrValue]
    })

  const path = vrPath(room.vr.imagePrefix, plan.colorSelection.id, vrOptions)

  if (room.vr && !room.vr.isEnabled) {
    return <NonVrImage src={path} alt={room.label} />
  }
  return <VrImage  src={path} alt={room.label} />
}

/**
 */
const NonVrImage = ({ src, alt }) => {
  return (
    <div className={styles.NonVrImageContainer}>
      <img alt={alt} className={styles.NonVrImage} src={src} />
    </div>
  )
}

const VrImage = ({ src, alt }) => {
  return (
    <a-scene
      vr-mode-ui="enabled: false"
      background="color: white"
      loading-screen="dotsColor: #DDD; backgroundColor: white;"
    >
      <a-camera look-controls="reverseMouseDrag: true" /> 
      <a-sky alt={alt} src={src} />
    </a-scene>
  )
}

///**
// */
//export const Images = () => (
//  <ErrorBoundary>
//  <a-scene
//    vr-mode-ui="enabled: false"
//    background="color: white"
//    loading-screen="dotsColor: #DDD; backgroundColor: white;"
//  >
//    <a-assets>
//      <RoomPlan.Rooms.Each>
//        {(room) => <Image key={room.id} />}
//      </RoomPlan.Rooms.Each>
//    </a-assets>
//    <Room.Properties.Id
//      render={({ value }) => <a-sky src={`#room_${value}`} />}
//    />
//  </a-scene>
//  </ErrorBoundary>
//)
//
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}
