import React from 'react'

import { Context } from '../Context'
import * as Options from 'modules/option/components'

const filterByColorSelection = (colorSelection) => (option) => {
  if (colorSelection && option.colorSelection) {
    return option.colorSelection === colorSelection
  } else {
    return true
  }
}

/**
 */
export const Each = ({
  filterByColorSelection: colorSelection = null,
  ...props
}) => {
  const { optionGroup } = React.useContext(Context)

  return (
    <Options.Each
      filter={(opt) =>
        optionGroup.options.includes(opt.id) &&
        filterByColorSelection(colorSelection)(opt)
      }
      {...props}
    />
  )
}
