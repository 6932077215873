import React from 'react'

import { Context } from '../Context'
import * as Renderers from 'atoms/Renderer'

/**
 */
const withProperty = (selector) => (Component) => (props) => {
  const { colorSelection } = React.useContext(Context)

  return <Component value={selector(colorSelection)} {...props} />
}

/**
 * Name
 */
export const Label = withProperty((entry) => entry.label)(
  // BaseComponent
  ({
    value,
    render: Renderer = Renderers.TextRenderer,
    default: defaultValue = '',
    ...props
  }) => {
    return <Renderer {...props} value={value ?? defaultValue} />
  },
)

/**
 * ID
 */
export const Id = withProperty((entry) => entry.id)(
  //
  ({
    value,
    render: Renderer = Renderers.TextRenderer,
    default: defaultValue = '',
    ...props
  }) => {
    return <Renderer {...props} value={value ?? defaultValue } />
  },
)
