import React from 'react'

import * as Room from 'modules/room/components/Reference'
import { RoomOptionGroup } from './RoomOptionGroup'

/**
 */
export const RoomOptionGroups = () => (
  <Room.OptionGroups.Each>
    {(optionGroup) => <RoomOptionGroup />}
  </Room.OptionGroups.Each>
)
