import React from 'react'

import * as Plan from 'modules/plan/components/Reference'
import * as ColorSelection from 'modules/color_selection/components/Reference'
import * as ColorSelections from 'modules/color_selection/components'
import * as styles from './styles.module.scss'

//const Selected = () => <ColorSelection.Properties.Label />
//
//const Selections = () => {
//  const { colorSelection: selected } = React.useContext(ColorSelection.Context)
//
//  return (
//    <ul className={ styles.Selections_Container }>
//      <ColorSelections.Each
//        filter={(entry) => entry.id !== selected.id}
//        render={({ colorSelection }) => (
//          <li className={ styles.Selections_Item}>
//            <Plan.Actions.ChangeColorSelection
//              className={styles.Link}
//              colorSelection={colorSelection}
//            >
//              <ColorSelection.Properties.Label />
//            </Plan.Actions.ChangeColorSelection>
//          </li>
//        )}
//      />
//    </ul>
//  )
//}
//
//export const Selector = () => {
//  const [isVisible, setVisibility] = React.useState(false)
//
//  return (
//    <dl className={styles.Container} onClick={ () => {setVisibility(!isVisible) }}>
//      <dt className={styles.Selected}>
//        <Selected />
//      </dt>
//      <dd className={ isVisible ? styles.Selections : styles.Selections_Hidden}>
//        <Selections />
//      </dd>
//    </dl>
//  )
//}

export const Selector = () => {
  const { colorSelection: selected } = React.useContext(ColorSelection.Context)

  return (
    <ul className={styles.Selector_Container}>
      <ColorSelections.Each
        render={({ colorSelection }) => {
          if (colorSelection.id === selected.id) {
            return (
              <ItemContainer isSelected={true}>
                <ColorSelection.Properties.Label />
              </ItemContainer>
            )
          } else {
            return (
              <ItemContainer isSelected={false}>
                <Plan.Actions.ChangeColorSelection
                  className={styles.Link}
                  colorSelection={colorSelection}
                >
                  <ColorSelection.Properties.Label />
                </Plan.Actions.ChangeColorSelection>
              </ItemContainer>
            )
          }
        }}
      />
    </ul>
  )
}

const ItemContainer = ({ children, isSelected }) => {
  const { colorSelection } = React.useContext(ColorSelection.Context)

  return (
    <li
      style={{
        background: colorSelection.colors.background,
        color: colorSelection.colors.font,
      }}
      className={
        isSelected ? styles.ItemContainer_Selected : styles.ItemContainer
      }
    >
      {children}
    </li>
  )
}
