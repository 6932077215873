import React, { useState } from 'react'

import { navigate } from 'gatsby'
import * as AddPlanForm from 'components/AddPlanForm'
import * as Building from 'modules/building/components/Reference'
import * as Links from 'components/Links'

import * as Layout from 'layouts/TopPageLayout'
import * as styles from './styles.module.scss'

const Title = () => (
  <div className={styles.TitleContainer}>
    <h1>
      <Building.Properties.NameEn className={styles.TitleText} />
      <small>
        <Building.Properties.NameJa className={styles.TitleSmallText} />
      </small>
    </h1>
  </div>
)

/**
 */
const Page = () => (
  <AddPlanForm.Container onSuccess={() => {
    navigate('/vr')
  }}>
    <Layout.Container styles={styles}>
      <Layout.Contents>
        <div className={styles.ContentsWrap}>
          <Title />
          <div className={styles.Content}>
            <AddPlanForm.FormFields />

            <AddPlanForm.Submit className={styles.Submit}>
              お部屋を観る
              <div className={styles.Submit_Icon} />
            </AddPlanForm.Submit>
            <Links.ToVR className={styles.ToVRButton}>
              前回の続きを観る
              <div className={styles.ToVRButton_Icon} />
            </Links.ToVR>
          </div>
        </div>
      </Layout.Contents>
      <Layout.Information>
        <div className={styles.Information}>
          <AddPlanForm.Preview />
        </div>
      </Layout.Information>
    </Layout.Container>
  </AddPlanForm.Container>
)

export default Page

const Test = () => <p>test</p>
