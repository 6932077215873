import React from 'react'

import { Consumer as PlanConsumer } from '../Context'
import { Provider as EstimationProvider } from '../../Estimation'

/**
 */
export const Provider = () => (
  <PlanConsumer>
    {({ children, estimation }) => (
      <EstimationProvider estimation={estimation}>
        {children}
      </EstimationProvider>
    )}
  </PlanConsumer>
)
