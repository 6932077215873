import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// Component imports
import { Page as Loading } from 'components/Loading'
// Local imports
import * as actions from '../actions'
import * as selectors from '../selectors'

const DefaultOnInit = () => {}

/**
 */
export const Initializer = connect(
  // mapStateToProps
  (state) => ({
    isInitialized: selectors.selectIsInitialized(state),
  }),
  // mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        init: actions.init,
      },
      dispatch,
    ),
)(
  // BaseComponent
  class BaseInitializer extends React.Component {
    componentDidMount = () => {
      const { init, isInitialized } = this.props

      if (!isInitialized) {
        init()
      }
    }

    //
    render = () => {
      const {
        children,
        isInitialized,
      } = this.props

      if(!isInitialized) {
        return <Loading>見積もりを初期化中...</Loading>
      }

      return children
    }
  },
)
