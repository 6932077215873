import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Provider } from './Reference/Provider'

/**
 * Initialize Data and init Provider
 */
export const Initializer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query BuildingProviderQuery {
        building: buildingYaml {
          name {
            ja
            en
          }
          topViewImage
          sideViewImage
        }
      }
    `}
    render={({ building }) => (
      <Provider building={building}>{children}</Provider>
    )}
  />
)
