import React from 'react'

import * as Form from 'modules/plan/components/Form'
import * as styles from './styles.module.scss'
import * as Layout from '../Layout/Section'

/**
 */
export const SelectPropertyType = ({ onChange }) => (
  <Layout.Container>
    <Layout.Title>Type</Layout.Title>
    <Layout.Description>お部屋のタイプをお選び下さい。</Layout.Description>
    <Layout.Content>
      <Form.Fields.PropertyType />
    </Layout.Content>
  </Layout.Container>
)
