// extracted by mini-css-extract-plugin
export var Conatiner = "styles-module--Conatiner--JG8f_";
export var Title = "styles-module--Title--3xFc9";
export var Title_Opened = "styles-module--Title_Opened--3gjzF";
export var Title_Label = "styles-module--Title_Label--1B-9Q";
export var Title_Arrow = "styles-module--Title_Arrow--1z5Ud";
export var Title_Arrow_Opened = "styles-module--Title_Arrow_Opened--1oGfc";
export var Content = "styles-module--Content--2vak9";
export var Content_Closed = "styles-module--Content_Closed--3ebv-";
export var Content_ItemContainer = "styles-module--Content_ItemContainer--2Hm_I";
export var Item = "styles-module--Item--112jg";
export var Item_Input = "styles-module--Item_Input--39jS7";
export var Item_Checkbox = "styles-module--Item_Checkbox--2AZNH";
export var Item_Checkbox_Checked = "styles-module--Item_Checkbox_Checked--iDq6_";
export var Item_Label = "styles-module--Item_Label--aheNz";
export var Item_Content = "styles-module--Item_Content---Ifp7";
export var Item_Content_Opened = "styles-module--Item_Content_Opened--1xqZG";
export var Item_Detail_Text = "styles-module--Item_Detail_Text--11wQK";
export var Item_Detail_ImageValuesConatiner = "styles-module--Item_Detail_ImageValuesConatiner--1lu-L";
export var Item_Detail_ImageValue = "styles-module--Item_Detail_ImageValue--3NtHt";
export var Item_Detail_ImageValue_Selected = "styles-module--Item_Detail_ImageValue_Selected--1QzZm";
export var Item_Detail_TextValuesConatiner = "styles-module--Item_Detail_TextValuesConatiner--P4BbI";
export var Item_Detail_TextValue = "styles-module--Item_Detail_TextValue--373Og";
export var Item_Detail_TextValue_Selected = "styles-module--Item_Detail_TextValue_Selected--26YED";
export var Item_Detail_Button = "styles-module--Item_Detail_Button--3tfbP";
export var Item_Detail_Button_Icon = "styles-module--Item_Detail_Button_Icon--1sPsy";
export var OptionDetail_Container = "styles-module--OptionDetail_Container--2QS2x";
export var OptionDetail_Title = "styles-module--OptionDetail_Title--2u3-v";
export var OptionDetail_CostConatiner = "styles-module--OptionDetail_CostConatiner--2XEk6";
export var OptionDetail_NameConatiner = "styles-module--OptionDetail_NameConatiner--2j2mJ";
export var OptionDetail_NameConatiner_Name = "styles-module--OptionDetail_NameConatiner_Name--30Njt";
export var OptionDetail_NameConatiner_ModelNumber = "styles-module--OptionDetail_NameConatiner_ModelNumber--281T_";
export var OptionDetail_Cost = "styles-module--OptionDetail_Cost--2VgaJ";
export var VR_ENABLED = "styles-module--VR_ENABLED--qfzbr";