import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../actions'
import * as selectors from '../selectors'

const DefaultLoading = () => <span>Loading PropertyTypes...</span>

/**
 */
const ReduxInitializer = connect(
  //mapStateToProps,
  (state) => ({
    isInitialized: selectors.selectIsInitialized(state),
  }),
  //mapDispatchToProps,
  (dispatch) =>
    bindActionCreators(
      {
        init: actions.init,
      },
      dispatch,
    ),
)(
  // BaseComponent
  class extends React.Component {
    componentDidMount = () => {
      const { isInitialized, init, data } = this.props

      if (!isInitialized) {
        init(data)
      }
    }

    render = () => {
      const {
        children,
        isInitialized,
        renderers: { Loading = DefaultLoading } = {},
        data,
      } = this.props

      if (!isInitialized) {
        return <Loading />
      }

      return <>{children}</>
    }
  },
)

/**
 * Data Initailizer with Gatsby
 * This then initialize redux
 */
export const Initializer = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query PropertyTypesInitializeQuery {
      propertyTypes: allPropertyTypesYaml {
        nodes {
          name
          label
          area
          balconies {
            basic
            roof
            service
            terrace
          }
          sideViewImage
        }
      }
    }
  `)

  return (
    <ReduxInitializer data={data.propertyTypes.nodes} {...props}>
      {children}
    </ReduxInitializer>
  )
}
