import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../../selectors'
import { Context } from './Context'

/**
 * Reference Provider
 */
export const Provider = connect((state, props) => ({
  roomPlan: props.roomPlan
    ? props.roomPlan
    : selectors.selectEntryById(state)(props.id),
}))(({ roomPlan, ...props }) => {

  return <Context.Provider value={{ isLoading: false, roomPlan }} {...props} />
})
