export const selectModule = (state) => state.module.room

export const selectIsInitialized = (state) => selectModule(state).init

export const selectDefault = (state) => selectModule(state).default

export const selectEntries = (state) => selectModule(state).entries

export const selectEntryById = (state) => (id) =>
  selectEntries(state).find((v) => v.id === id)

export const selectEntryByKeyAndPlan = (state) => (key, plan) =>
  selectEntries(state).find((v) => v.key === key && v.roomPlan.id === plan)
