import React from 'react'

import { StyleConsumer } from './StyleContext'

export * from './StyleContext'

export const Pair = ({ className, children }) => (
  <StyleConsumer>
    {(styles) => (
      <dl className={className ? className : styles.Pair}>{children}</dl>
    )}
  </StyleConsumer>
)

export const Key = ({ className, children }) => (
  <StyleConsumer>
    {(styles) => (
      <dt className={className ? className : styles.Key}>{children}</dt>
    )}
  </StyleConsumer>
)

export const Value = ({ className, children }) => (
  <StyleConsumer>
    {(styles) => (
      <dd className={className ? className : styles.Value}>{children}</dd>
    )}
  </StyleConsumer>
)
