import React from 'react'

import * as styles from './styles.module.scss'
import * as Room from 'modules/room/components/Reference'
import * as RoomPlan from 'modules/room_plan/components/Reference'
import * as PropertyType from 'modules/property_type/components/Reference'
import * as LayoutImageModal from 'components/LayoutImageModal'

import { Context as ModalContext } from 'atoms/Modal'
/**
 */
export const LayoutInfo = () => {
  const { openModal } = React.useContext(ModalContext)

  const { roomPlan } = React.useContext(RoomPlan.Context)
  const { propertyType } = React.useContext(PropertyType.Context)

  return (
    <div className={styles.Container}>
      <Room.Properties.LayoutImage className={styles.LayoutImage} />
      <div className={styles.ZoomButton}>
        <RoomPlan.Properties.LayoutImage
          render={({ value }) => (
            <a
              onClick={() =>
                openModal(
                  <PropertyType.Provider propertyType={propertyType}>
                    <RoomPlan.Provider roomPlan={roomPlan}>
                      <LayoutImageModal.Content
                        image={value.src}
                        alt={value.alt}
                      />
                    </RoomPlan.Provider>
                  </PropertyType.Provider>,
                )
              }
              className={styles.ModalButton}
            >
              間取り図を拡大表示
            </a>
          )}
        />
        <div className={styles.ZoomButton_Label}></div>
      </div>
    </div>
  )
}
