import React from 'react'

import * as BuildingRef from 'modules/building/components/Reference'
import * as styles from './styles.module.scss'

/**
 */
const LandImageSection = () => (
  <div className={styles.Building_LandImageSection}>
    <BuildingRef.Properties.TopViewImage className={styles.Image} />
  </div>
)

/**
 */
const ImageSection = () => (
  <div className={styles.Building_ImageSection}>
    <BuildingRef.Properties.SideViewImage className={styles.Image} />
  </div>
)

/**
 */
export const Building = () => (
  <div className={styles.Building_Container}>
    <LandImageSection />
    <ImageSection />
  </div>
)
