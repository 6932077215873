import React from 'react'

import { Context } from '../Context'
import * as Renderers from 'atoms/Renderer'
import * as ImageHelper from 'utils/ImageHelper'

/**
 */
export const Consumer = ({ children }) => (
  <Context.Consumer>
    {({ propertyType }) => children(propertyType)}
  </Context.Consumer>
)

/**
 *
 */
export const Name = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ name: value = defaultValue }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const Label = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ label: value = defaultValue }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

///**
// *
// */
//export const LayoutText = ({ render: Renderer = Renderers.TextRenderer, default: defaultValue = "", ...props}) => (
//  <Consumer>{ ({
//    layout: {
//      text: value = defaultValue,
//    } = {},
//  }) => {
//    return (<Renderer {...props}>{value}</Renderer>)
//  }}</Consumer>
//)
//
///**
// *
// */
//export const LayoutSubText = ({ render: Renderer = Renderers.TextRenderer, default: defaultValue = "", ...props}) => (
//  <Consumer>{ ({
//    layout: {
//      subtext: value = defaultValue,
//    } = {},
//  }) => {
//    return (<Renderer {...props}>{value}</Renderer>)
//  }}</Consumer>
//)

/**
 *
 */
export const AreaValue = ({
  render: Renderer = Renderers.NumberRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ area: value = defaultValue }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const BalconyBasicValue = ({
  render: Renderer = Renderers.NumberRenderer,
  ...props
}) => (
  <Consumer>
    {({ balconies: { basic: value } }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const BalconyTotalValue = ({
  render: Renderer = Renderers.NumberRenderer,
  ...props
}) => (
  <Consumer>
    {({ balconies = {} }) => {
      return (
        <Renderer
          {...props}
          value={Object.values(balconies).reduce((z, x) => z + x, 0)}
        />
      )
    }}
  </Consumer>
)

/**
 *
 */
export const SideViewImage = ({
  render: Renderer = Renderers.ImageRenderer,
  default: defaultValue = null,
  ...props
}) => (
  <Consumer>
    {({ sideViewImage: src }) => {
      return (
        <Renderer
          value={{ src: ImageHelper.resolve(src), alt: 'タイプ画像' }}
          {...props}
        />
      )
    }}
  </Consumer>
)
