import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'
import { Provider } from './Reference'

/**
 *
 */
export const Each = connect(
  // mapStateToProps
  (state) => ({
    rooms: selectors.selectEntries(state),
  }),
)(
  // BaseComponent
  ({ rooms, children, filter = (room) => true }) =>
    rooms.filter(filter).map((room) => (
      <Provider key={room.id} room={room}>
        {children(room)}
      </Provider>
    )),
)
