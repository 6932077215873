import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as selectors from '../../selectors'
import * as actions from '../../actions'

const DefaultRenderer = ({ active, id, change, children, ...props }) => (
  <a
    onClick={ e => {
      if(active.id !== id) {
        change(id)
      }
    }}
    { ...props }
  >{ children }</a>
)

/**
 */
export const ChangeActive = connect(
  (state) => ({
    active: selectors.selectActiveEntry(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        change: actions.setActiveEntry,
      },
      dispatch,
    ),
)(({ active, render: Renderer = DefaultRenderer, change, ...props }) => (
  <Renderer change={ change } active={ active } {...props} />
))
