export const selectModule = (state) => state.module.colorSelection

export const selectIsInitialized = (state) => selectModule(state).init

export const selectEntries = (state) => selectModule(state).entries

export const selectEntryById = (state) => (id) =>
  selectEntries(state).find((v) => v.id === id)

export const selectDefaultEntry = (state) =>
  selectEntries(state).find((v) => v.default)
