import {
  all,
  takeLeading,
  take,
  race,
  put,
  call,
  select,
} from 'redux-saga/effects'

import * as form from 'redux-form'
import * as consts from './consts'
import { v4 as uuid } from 'uuid'
// Local imports
import { info, actionName } from './utils'
import * as actions from './actions'
import { initPlanState } from './reducers'
import * as selectors from './selectors'

import * as propertySelectors from 'modules/property/selectors'
import * as propertyTypeSelectors from 'modules/property_type/selectors'
import * as roomPlanSelectors from 'modules/room_plan/selectors'
import * as roomSelectors from 'modules/room/selectors'
import * as optionSelectors from 'modules/option/selectors'
import * as colorSelectionSelectors from 'modules/color_selection/selectors'

/**
 * Initialize
 */
function* handleSetProperty(action) {
  try {
    info(action)

    // preload list
    //yield put(actions.setPropertySuccess())
  } catch (e) {
    console.error(e)
    //yield put(actions.setPropertyFailure(e))
  }
}

/**
 */
function* handleInit(action) {
  try {
    info(action)

    // preload list
    yield put(actions.initSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.initFailure(e))
  }
}

function* handleAddEntry(action) {
  try {
    info(action)

    yield put(actions.addEntrySuccess(action.payload.data))
    // calculate entry estimation
    yield put(actions.estimate())
  } catch (e) {
    console.error(e)
    yield put(actions.addEntryFailure(e))
  }
}

/**
 */
function* handleSubmitAddForm(action) {
  try {
    info(action)

    yield put(form.startSubmit(consts.FORM_ADD))

    const propertyType = yield select((state) =>
      propertyTypeSelectors.selectEntryByName(state)(
        action.payload.data.propertyType,
      ),
    )

    if (!propertyType) {
      throw new Error(
        `Invalid PropertyType: ${action.payload.data.propertyType}`,
      )
    }

    const property = yield select((state) =>
      propertySelectors.selectEntryByKey(state)(action.payload.data.property),
    )
    if (!property) {
      throw new Error(`Invalid Property: ${action.payload.data.property}`)
    }

    const roomPlan = yield select((state) =>
      roomPlanSelectors.selectEntryById(state)(action.payload.data.roomPlan),
    )
    if (!roomPlan) {
      throw new Error(`Invalid RoomPlan: ${action.payload.data.roomPlan}`)
    }

    const colorSelection = yield select((state) =>
      colorSelectionSelectors.selectEntryById(state)(
        action.payload.data.colorSelection,
      ),
    )
    if (!colorSelection) {
      throw new Error(
        `Invalid ColorSelection: ${action.payload.data.colorSelection}`,
      )
    }

    //
    const roomOptionIds = (yield select((state) =>
      roomSelectors.selectEntries(state),
    ))
      .filter((room) => roomPlan.rooms.includes(room.id))
      .map((room) => room.optionGroups.map((group) => group.options))
      .flat(2)

    const roomOptionValues = (yield select(optionSelectors.selectEntries))
      .filter((option) => roomOptionIds.includes(option.id))
      .map((option) => [
        option.id,
        {
          option: option,
          value: option.values.find((v) => v.value === option.defaultValue),
        },
      ])

    const entry = {
      ...initPlanState,
      ...action.payload.data,
      property,
      propertyType,
      roomPlan,
      colorSelection,
      //
      roomOptionValues: Object.fromEntries(roomOptionValues),
      id: uuid(),
      name: `${property.name} (${propertyType.name} Type)`,
    }

    yield put(actions.addEntry(entry))

    const [success, failure] = yield race([
      actionName('/ADD_ENTRY/SUCCESS'),
      actionName('/ADD_ENTRY/FAILURE'),
    ])

    if (failure) {
      throw failure.payload.error
    }

    yield put(form.setSubmitSucceeded(consts.FORM_ADD))
  } catch (e) {
    console.error(e)
    yield put(form.setSubmitFailed(consts.FORM_ADD, e))
  }
}

const calcAdditionalCost = (plan) => {
  return Object.values(plan.roomOptionValues)
    .map(({ value }) => {
      return value.cost.fixed
    })
    .reduce((sum, v) => sum + v, 0)
}

/**
 */
function* handleEstimate(action) {
  try {
    info(action)

    let entry
    if (action.payload.plan) {
      entry = yield select((state) =>
        selectors.selectEntryById(state)(action.payload.plan),
      )
    } else {
      entry = yield select(selectors.selectActiveEntry)
    }

    console.info(entry)

    const basecost = entry.property.cost
    const additionalCost = calcAdditionalCost(entry)
    const tax = 0

    const total = basecost + additionalCost + tax

    console.log(JSON.stringify(basecost, additionalCost, tax, total))

    yield put(
      actions.estimateSuccess(entry.id, {
        basecost,
        additionalCost,
        tax,
        total,
      }),
    )
  } catch (e) {
    console.error(e)
    yield put(actions.estimateFailure(e))
  }
}

/**
 */
function* handleUpdateEntry(action) {
  try {
    info(action)

    yield put(actions.estimate(action.payload.entry.id))
  } catch (e) {
    console.error(e)
  }
}

/**
 */
function* handleSetRoomOption(action) {
  try {
    info(action)

    const entry = yield select((state) =>
      action.payload.plan
        ? selectors.selectEntryById(state)(action.payload.plan)
        : selectors.selectActiveEntry(state),
    )

    const updated = {
      ...entry,
      roomOptionValues: {
        ...entry.roomOptionValues,
        [action.payload.option]: {
          ...entry.roomOptionValues[action.payload.option],
          value: action.payload.value,
        },
      },
    }

    yield put(actions.updateEntry(updated))
  } catch (e) {
    console.error(e)
    yield put(actions.setRoomOptionEntryFailure(e))
  }
}

function* handleChangeColorSelection(action) {
  try {
    info(action)
    const entry = yield select((state) =>
      action.payload.plan
        ? selectors.selectEntryById(state)(action.payload.plan)
        : selectors.selectActiveEntry(state),
    )
    console.log(action)

    const updated = {
      ...entry,
      colorSelection: action.payload.colorSelection,
    }

    console.log('uuu', updated)
    yield put(actions.updateEntry(updated))
  } catch(err) {
    console.error(err)
  }
}

/**
 */
export function* sagas() {
  yield all([
    takeLeading(actionName('/INIT/REQUEST'), handleInit),
    takeLeading(actionName('/SET_PROPERTY/REQUEST'), handleSetProperty),
    takeLeading(actionName('/ADD_ENTRY/REQUEST'), handleAddEntry),
    takeLeading(actionName('/CHANGE_COLOR_SELECTION'), handleChangeColorSelection),
    takeLeading(actionName('/UPDATE_ENTRY'), handleUpdateEntry),
    takeLeading(actionName('/SUBMIT_ADD_FORM'), handleSubmitAddForm),
    takeLeading(actionName('/ESTIMATE/REQUEST'), handleEstimate),
    takeLeading(actionName('/SET_ROOM_OPTION'), handleSetRoomOption),
  ])
}
