import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'
import { filterByPropertyType } from '../utils'

/**
 *
 */
export const All = connect(
  // mapStateToProps
  (state) => ({
    plans: selectors.selectEntries(state),
  }),
)(
  // BaseComponent
  ({ plans, children, propertyType }) =>
    children(plans.filter(filterByPropertyType(propertyType))),
)
