import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

export const {
  init: { request: init, success: initSuccess, failure: initFailure },
  addEntry: {
    request: addEntry,
    success: addEntrySuccess,
    failure: addEntryFailure,
  },
  removeEntry,
  updateEntry,
  setActiveEntry,
  setRoomOption,
  changeColorSelection,
  submitAddForm,
  estimate: {
    request: estimate,
    success: estimateSuccess,
    failure: estimateFailure,
  },
} = createActions(
  {
    INIT: {
      REQUEST: () => ({}),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
    ADD_ENTRY: {
      REQUEST: (data) => ({ data }),
      SUCCESS: (plan) => plan,
      FAILURE: () => ({}),
    },
    REMOVE_ENTRY: (id) => ({ id }),
    // replace current entry
    UPDATE_ENTRY: (entry) => ({ entry }),
    SET_ACTIVE_ENTRY: (id) => ({ id }),
    SET_ROOM_OPTION: (option, value, plan = null) => ({ option, value, plan }),
    CHANGE_COLOR_SELECTION: (colorSelection, plan = null) => ({ colorSelection, plan }),
    SUBMIT_ADD_FORM: (data) => ({ data }),
    ESTIMATE: {
      REQUEST: (plan = null) => ({ plan }),
      SUCCESS: (plan, estimation) => ({ plan, estimation }),
      FAILURE: (error) => ({ error }),
    },
  },
  {
    prefix: ACTION_PREFIX,
  },
)
