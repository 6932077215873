import React from 'react'

import { connect } from 'react-redux'

import { Provider as PropertyTypeProvider } from 'modules/property_type/components/Reference'
import { Provider as PropertyProvider } from 'modules/property/components/Reference'
import { Provider as RoomPlanProvider } from 'modules/room_plan/components/Reference'
import { Provider as ColorSelectionProvider } from 'modules/color_selection/components/Reference'

import { Context } from './Context'
import * as selectors from '../../selectors'
import { info } from '../../utils'

/**
 *
 */
export const Provider = connect(
  // mapStateToProps
  (state, { plan, id }) => ({
    plan: plan ?? selectors.selectEntryById(state)(id),
  }),
)(
  //
  ({ plan, onEmpty, children, ...props }) => (
    <Context.Provider value={{ plan }} {...props}>
      <PropertyTypeProvider propertyType={plan.propertyType}>
        <PropertyProvider property={plan.property}>
          <RoomPlanProvider roomPlan={plan.roomPlan}>
            <ColorSelectionProvider colorSelection={plan.colorSelection}>
              {children}
            </ColorSelectionProvider>
          </RoomPlanProvider>
        </PropertyProvider>
      </PropertyTypeProvider>
    </Context.Provider>
  ),
)
