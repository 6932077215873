import React from 'react'

import * as Option from 'modules/option/components/Reference'
import * as OptionValue from 'modules/option/components/Value'
import * as styles from './styles.module.scss'

import * as Plan from 'modules/plan/components/Reference'

/**
 */
export const RoomOptionValues = () => {
  const {
    option: { id: option },
  } = React.useContext(Option.Context)

  return (
    <Plan.OptionValues.One id={option}>
      {(selected) => (
        <>
          <TextValues />

          <SelectedValueDetail />
        </>
      )}
    </Plan.OptionValues.One>
  )
}

/**
 */
const ImageValues = () => {
  const { value: selected } = React.useContext(OptionValue.Context)
  const {
    option: { id: option },
  } = React.useContext(Option.Context)

  return (
    <Plan.SelectOption>
      {({ selectOption }) => (
        <div className={styles.Item_Detail_ImageValuesConatiner}>
          <Option.Values.Each>
            {(value) => (
              <ImageValue
                isSelected={selected.value === value.value}
                onSelect={() => selectOption(option, value)}
              />
            )}
          </Option.Values.Each>
        </div>
      )}
    </Plan.SelectOption>
  )
}

/**
 */
const ImageValue = ({ isSelected, onSelect }) => (
  <div className={styles.Item_Detail_ImageValue} onClick={(e) => onSelect()}>
    <OptionValue.Properties.LabelOrCode />
  </div>
)

/**
 */
const TextValues = () => {
  const { value: selected } = React.useContext(OptionValue.Context)
  const {
    option: { id: option },
  } = React.useContext(Option.Context)

  return (
    <Plan.SelectOption>
      {({ selectOption }) => (
        <div className={styles.Item_Detail_TextValuesConatiner}>
          <Option.Values.Each>
            {(value) => (
              <TextValue
                isSelected={selected.value === value.value}
                onSelect={() => {
                  selectOption(option, value)
                }}
              />
            )}
          </Option.Values.Each>
        </div>
      )}
    </Plan.SelectOption>
  )
}

/**
 */
const TextValue = ({ isSelected, onSelect }) => (
  <div
    className={
      isSelected
        ? styles.Item_Detail_TextValue_Selected
        : styles.Item_Detail_TextValue
    }
    onClick={(e) => onSelect()}
  >
    <OptionValue.Properties.LabelOrCode />
  </div>
)

/**
 */
const SelectedValueDetail = () => {
  return (
    <div>
      <div className={styles.OptionDetail_Container}>
        <div className={styles.OptionDetail_Title}>追加オプション料金</div>
        <div className={styles.OptionDetail_CostConatiner}>
          <div className={styles.Item_Detail_NameConatiner}>
            <div className={styles.OptionDetail_NameConatiner_ModelNumber}>
              <OptionValue.Properties.HasCode>
                型番：
                <OptionValue.Properties.Code />
              </OptionValue.Properties.HasCode>
            </div>
          </div>
          <div className={styles.OptionDetail_Cost}>
            <OptionValue.Properties.Cost format="0,0" suffix="円" />
          </div>
        </div>
      </div>
      <OptionValue.Properties.HasURL>
        <div className={styles.Item_Detail_Button}>
          商品詳細はこちら
          <div className={styles.Item_Detail_Button_Icon} />
        </div>
      </OptionValue.Properties.HasURL>
    </div>
  )
}
