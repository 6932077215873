import React from 'react'

import { Link } from 'gatsby'
import * as Plan from 'modules/plan/components/Reference'

/**
 */
export const ToTopPage = ({ children = 'TOP', ...props }) => (
  <Link to="/" {...props}>
    {children}
  </Link>
)

/**
 */
export const ToVR = ({ children = 'VR', ...props }) => (
  <Plan.Active>
    {(plan) => {
      if (plan) {
        return (
          <Link to="/vr" {...props}>
            {children}
          </Link>
        )
      }
      return null
    }}
  </Plan.Active>
)
