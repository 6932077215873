import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Context } from './Context'
import * as Renderers from 'atoms/Renderer'

/**
 *
 */
export const Label = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => {
  const {
    option: { label: value = defaultValue },
  } = React.useContext(Context)

  return <Renderer {...props} value={value} />
}

/**
 *
 */
export const Description = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => {
  const {
    option: { description: value = defaultValue },
  } = React.useContext(Context)

  return <Renderer {...props} value={value} />
}
