// @flow
import { handleActions } from 'redux-actions'

import { ACTION_PREFIX } from './consts'

export const initState = {
  init: false,
  entries: [],
}

/**
 * Root reducer
 */
export const reducers = handleActions(
  {
    INIT: {
      REQUEST: (state, action) => ({
        ...state,
        entries: action.payload.entries,
      }),
      SUCCESS: (state, action) => ({
        ...state,
        init: true,
      }),
    },
  },
  initState,
  {
    prefix: ACTION_PREFIX,
  },
)
