import React from 'react'

import { Context } from "./Context"

const ChildrenRenderer = ({ children }) => <>{children}</>

/**
 */
export const IsVrEnabled = ({
  children,
  render: Renderer = ChildrenRenderer,
}) => {
  const {
    option: { isSupportVr = false },
  } = React.useContext(Context)

  if (isSupportVr) {
    return <Renderer>{children}</Renderer>
  }
  return null
}
