import React, { useState } from 'react'

import * as Plan from 'modules/plan/components/Reference'
import { ActiveProvider } from 'modules/plan/components'
import { navigate } from 'gatsby'

import * as Layout from 'layouts/VRPageLayout'
import * as Partials from 'partials/VrIndexPage'
import * as RoomPlan from 'modules/room_plan/components/Reference'
import { Helmet } from 'react-helmet'

/**
 */
const Content = () => (
  <Layout.Container>
    <Layout.Background>
      <Partials.Images />
    </Layout.Background>
    <Layout.RoomLayout>
      <Partials.LayoutInfo />
    </Layout.RoomLayout>
    <Layout.Message>
      <RoomPlan.Properties.VRMessage />
    </Layout.Message>
    <Layout.Menu>
      <Partials.Menu />
    </Layout.Menu>
  </Layout.Container>
)

const Page = () => {
  return (
    <ActiveProvider onEmpty={() => navigate('/')}>
      <Partials.DataInitializer>
        <Content />
      </Partials.DataInitializer>
    </ActiveProvider>
  )
}

export default Page
