// extracted by mini-css-extract-plugin
export var PropertyTypeImage_Container = "styles-module--PropertyTypeImage_Container--1o-NB";
export var PropertyTypeName_Container = "styles-module--PropertyTypeName_Container--5pn-_";
export var PropertyTypeName_Name = "styles-module--PropertyTypeName_Name--1iwWo";
export var LayoutText = "styles-module--LayoutText--T87HG";
export var LayoutSubText = "styles-module--LayoutSubText--1B_VK";
export var LayoutImage_Container = "styles-module--LayoutImage_Container--adc3L";
export var PropertyImage_Container = "styles-module--PropertyImage_Container--3HnbK";
export var Image = "styles-module--Image--13UV4";
export var BalconyArea_Container = "styles-module--BalconyArea_Container--jn1mE";
export var BalconyArea_Value = "styles-module--BalconyArea_Value--wpXjJ";
export var BalconyArea_Data = "styles-module--BalconyArea_Data--3C17S";
export var BalconyArea_DataTitle = "styles-module--BalconyArea_DataTitle--2HxX_";
export var OccupiedArea_Container = "styles-module--OccupiedArea_Container--1xXWx";
export var OccupiedArea_Value = "styles-module--OccupiedArea_Value--1JVx2";
export var OccupiedArea_Data = "styles-module--OccupiedArea_Data--2WLwt";
export var OccupiedArea_DataTitle = "styles-module--OccupiedArea_DataTitle--3qUYI";
export var ModalButton = "styles-module--ModalButton--1AgpZ";