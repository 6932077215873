import React from 'react'

import * as Room from 'modules/room/components/Reference'
import { RoomOptionGroups } from './RoomOptions'
import * as styles from './styles.module.scss'

/**
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
const RoomName = () => (
  <div className={styles.OptionTitle}>
    <Room.Properties.Label className={styles.OptionName} />
  </div>
)

/**
 */
const RoomOptions = () => <RoomOptionGroups />

/**
 */
export const ActiveRoomOptions = () => (
  <Container>
    <RoomName />
    <RoomOptions />
  </Container>
)
