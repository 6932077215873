export const selectModule = (state) => state.module.roomPlan

export const selectIsInitialized = (state) => selectModule(state).init

export const selectDefault = (state) => selectModule(state).default

export const selectEntries = (state) => selectModule(state).entries

export const selectEntryById = (state) => (id) =>
  selectEntries(state).find((v) => v.id === id)
