import React from 'react'
import { Link } from 'gatsby'

import * as styles from './default.module.scss'

export const Container = ({ children }) => (
  <div className={styles.Container}>
    <div className={styles.ContainerInner}>{children}</div>
  </div>
)

export const ErrorCode = ({ children }) => (
  <h1 className={styles.ErrorCode}>{children}</h1>
)

export const Title = ({ children }) => (
  <h2 className={styles.Tilte}>{children}</h2>
)

export const Description = ({ children }) => (
  <div className={styles.Description}>{children}</div>
)

export const Navi = ({ children }) => (
  <div className={styles.Navi}>{children}</div>
)
