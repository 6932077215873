import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../../../actions'

const DefaultRenderer = ({
  colorSelection,
  changeColorSelection,
  children,
  ...props
}) => (
  <a
    onClick={() => {
      changeColorSelection(colorSelection)
    }}
    {...props}
  >
    {children}
  </a>
)

/**
 */
export const ChangeColorSelection = connect(
  //
  null,
  (dispatch) =>
    bindActionCreators(
      {
        changeColorSelection: actions.changeColorSelection,
      },
      dispatch,
    ),
)(
  //
  ({ changeColorSelection, render: Renderer = DefaultRenderer, ...props }) => (
    <Renderer changeColorSelection={changeColorSelection} {...props} />
  ),
)
