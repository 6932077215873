import React from 'react'

import * as styles from './styles.module.scss'

const Context = React.createContext({ visibility: true })

/**
 */
export const Container = ({ children }) => {
  const [visibility, setVisibility] = React.useState(true)
  return (
    <Context.Provider value={{ visibility, setVisibility }}>
      <div className={styles.Container}>{children}</div>
    </Context.Provider>
  )
}

/**
 */
export const Background = ({ children }) => (
  <div className={styles.VR}>{children}</div>
)

/**
 */
export const Menu = ({ children }) => (
  <Context.Consumer>
    {({ visibility, setVisibility }) => (
      <div
        className={styles.SideMenu}
        style={{
          right: visibility ? null : '-285px',
        }}
      >
        <div className={styles.SideMenu_Inner}>
          <a
            className={styles.SideMenu_Toggle}
            onClick={(e) => setVisibility(!visibility)}
          >
            <span
              className={styles.SideMenu_Toggle_Icon}
              style={{
                transform: visibility ? null : 'rotate(180deg)',
              }}
            >
              <div className={styles.SideMenu_Toggle_Icon_Image} />
            </span>
          </a>
          <div className={styles.SideMenu_Content_Wrapper}>
            <div className={styles.SideMenu_Content}>{children}</div>
          </div>
        </div>
      </div>
    )}
  </Context.Consumer>
)

/**
 */
export const RoomLayout = ({ children }) => (
  <div className={styles.InfoContainer}>{children}</div>
)

/**
 */
export const Message = ({ children }) => (
  <div className={styles.MessageContainer}>{children}</div>
)
