import React from 'react'

// Import de
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../actions'
import * as selectors from '../../selectors'

import * as DefaultStyles from './default.module.scss'
import { StyleProvider } from './StyleContext'
import * as Fields from './Fields'
import { StyleProvider as InputStyleProvider } from 'atoms/Inputs/Styles'

/**
 *
 */
const ReduxForm = reduxForm({
  form: 'plan_add',
})(
  // BaseComponent
  ({ form, handleSubmit, submitHandler, children, styles = DefaultStyles }) => (
    <StyleProvider styles={styles}>
      <form id={form} onSubmit={handleSubmit(submitHandler)}>
        <InputStyleProvider styles={styles}>{children}</InputStyleProvider>
      </form>
    </StyleProvider>
  ),
)

/**
 */

/**
 * Connected Form
 */
export const AddForm = connect(
  // mapStateToProps
  null,
  // mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        submitHandler: actions.submitAddForm,
      },
      dispatch,
    ),
)(
  // BaseComponent
  ({ children, id, project, onSuccess, ...props }) => (
    <ReduxForm {...props} onSubmitSuccess={ onSuccess }>
      {children}
    </ReduxForm>
  ),
)
