import React from 'react'
//
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormName, isPristine, isSubmitting, reset } from 'redux-form'
import { StyleConsumer } from './StyleContext'

const Base = ({
  className,
  styles: overwiteStyles,
  pristine,
  submitting,
  reset,
  dispatch,
  children = 'reset',
  ...props
}) => (
  <StyleConsumer sytles={overwiteStyles}>
    {({ styles }) => (
      <button
        type="button"
        disabled={pristine || submitting}
        onClick={reset}
        {...props}
      >
        {children}
      </button>
    )}
  </StyleConsumer>
)

const mapStateToProps = (state, { form }) => ({
  pristine: isPristine(form)(state),
  submitting: isSubmitting(form)(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      reset,
    },
    dispatch,
  )

const Connected = connect(mapStateToProps, mapDispatchToProps)(Base)

/**
 *
 */
export const Reset = ({ children, ...props }) => (
  <FormName>
    {({ form }) => (
      <Connected form={form} {...props}>
        {children}
      </Connected>
    )}
  </FormName>
)
