import React from 'react'

import * as Inputs from 'atoms/Inputs'
import * as RoomPlan from 'modules/room_plan/components/Reference'
import { ActiveRoomContext } from '../../ActiveRoom'

import * as styles from './styles.module.scss'

/**
 */
export const SelectRoom = ({ className }) => {
  const { selectedRoom, setSelectedRoom } = React.useContext(ActiveRoomContext)
  return (
    <div className={className ? className : styles.Container}>
      <RoomPlan.Rooms.All>
        {(rooms) => (
          <Inputs.Choices
            styles={styles}
            options={rooms.map((room) => ({
              value: room.key,
              label: room.label,
            }))}
            value={selectedRoom}
            onChange={(e) => setSelectedRoom(e.target.value)}
          />
        )}
      </RoomPlan.Rooms.All>
    </div>
  )
}
