import React from 'react'

import * as Form from 'modules/plan/components/Form'

import { Building } from './Building'
import { PropertyType } from './PropertyType'
import { Property } from './Property'
import { RoomPlan } from './RoomPlan'

/**
 */
export const Preview = () => (
  <Form.Preview
    renderBuildingPreview={Building}
    renderPropertyTypePreview={PropertyType}
    renderPropertyPreview={Property}
    renderRoomPlanPreview={RoomPlan}
  />
)
