import React from 'react'
import ReactDOM from 'react-dom'

import * as styles from './styles.module.scss'

export const Context = React.createContext({ content: null })

const DefaultContainerRenderer = ({ id, children }) => (
  <Consumer>
    {({ content, closeModal }) => (
      <div
        className={styles.Container}
        style={{ display: content ? null : 'none' }}
      >
        <div className={styles.Background} onClick={() => closeModal()} />
        <div id={id} className={styles.Content}>
          {children}
        </div>
      </div>
    )}
  </Consumer>
)

/**
 * <Modal.Provider container={ (<div id="modal" className={styles.Modal } />)}}>{ ({ openModal }) => (
 *  <button onClick={ e => {
 *    openModal(<Modal.Content>
 *      test
 *    </Modal.Content>)
 *  }}
 * )}</Modal.Provider>
 */
export const Initializer = ({
  children,
  container = 'modal',
  renderContainer: ContainerRenderer = DefaultContainerRenderer,
  isOpened: inputIsOpened,
  setOpened: inputSetOpened,
  defaultOpened = false,
}) => {
  // default state
  const [content, setContent] = React.useState(defaultOpened)

  const openModal = (content) => setContent(content)

  const closeModal = () => setContent(null)

  return (
    <Context.Provider value={{ content, openModal, closeModal }}>
      {children}
      <ContainerRenderer id={container}>{content}</ContainerRenderer>
    </Context.Provider>
  )
}

export const Consumer = Context.Consumer

export const Content = ({ children }) => (
  <div className={styles.Content}>{children}</div>
)

///**
// */
//export class Modal extends React.Component {
//  constructor(props) {
//    super(props)
//    this.rootEl = document.getElementById(props.container);
//  }
//
//  render = () => {
//    return ReactDOM.createPortal(this.props.content, this.rootEl)
//  }
//}
