import React from 'react'

import * as DefaultStyles from './default.module.scss'

/**
 */
export const StyleContext = React.createContext(DefaultStyles)

/**
 */
export const StyleProvider = ({ styles, children }) => (
  <StyleContext.Provider value={styles ? styles : DefaultStyles}>
    {children}
  </StyleContext.Provider>
)

/**
 */
export const StyleConsumer = ({ styles, children }) => (
  <StyleContext.Consumer>{(styles) => children(styles)}</StyleContext.Consumer>
)
