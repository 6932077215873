export const selectModule = (state) => state.module.plan

//export const selectProperty = (state) => selectModule(state).property
//
//export const selectPropertyType = (state) => selectModule(state).propertyType
//
//export const selectRoomPlan = (state) => selectModule(state).roomPlan
//
//export const selectRoomOptions = (state) => selectModule(state).roomOptions
//
//export const selectStatement = (state) => selectModule(state).statement
//

/**
 */
export const selectActive = (state) => selectModule(state).active

/**
 */
export const selectEntries = (state) => selectModule(state).entries

/**
 */
export const selectEntryById = (state) => (id) => {
  const entry = selectEntries(state).find((entry) => entry.id === id)
  if (!entry) {
    throw new Error(`Plan ${id} is not exists.`)
  }
  return entry
}

/**
 */
export const selectIsInitialized = (state) => selectModule(state).isInitialized

/**
 */
export const selectActiveEntry = (state) =>
  selectEntries(state).find((entry) => entry.id === selectActive(state))
