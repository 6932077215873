import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../../selectors'

import { Context } from './Context'

const DefaultLoading = <span>Loading...</span>

const selectRoom =
  ({ id, room, roomKey, roomPlan }) =>
  (state) => {
    if (room) {
      return room
    } else if (id) {
      return selectors.selectEntryById(state)(id)
    } else if (roomKey && roomPlan) {
      return selectors.selectEntryByKeyAndPlan(state)(roomKey, roomPlan)
    } else {
      console.error({ id, room, roomKey, roomPlan })
      throw new Error(
        `Either "room" or "id" or "roomKey" and "roomPlan" has to be specified.`,
      )
    }
  }

/**
 *
 */
export const Provider = connect((state, props) => {
  return {
    isLoaded: selectors.selectIsInitialized(state),
    room: selectRoom(props)(state),
  }
})(
  ({
    id,
    roomKey,
    roomPlan,
    room,
    isLoaded,
    renderers: { Loading = DefaultLoading } = {},
    ...props
  }) => {
    if (isLoaded && !room) {
      if (id) {
        return null
        throw new Error(`Room for ${JSON.stringify({ id })} is not existed.`)
      } else if (roomKey && roomPlan) {
        return null
        throw new Error(
          `Room for ${JSON.stringify({ roomKey, roomPlan })} is not existed.`,
        )
      }
    }

    return <Context.Provider {...props} value={{ room, isLoaded }} />
  },
)
