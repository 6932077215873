import React from 'react'

import * as Elements from './Elements'

import * as styles from './styles.module.scss'

/**
 */
const ImageSection = () => (
  <div className={styles.Property_ImageSection}>
    <Elements.PropertyImage  className={styles.Image} />
  </div>
)

/**
 */
const InfoSection = () => (
  <div className={styles.Property_InfoSection}>
    <LayoutSection />
  </div>
)

/**
 */
const LayoutSection = () => (
  <div className={styles.Property_LayoutSection}>
    <Elements.PropertyTypeName />
    <div>
      <Elements.OccupiedArea />
      <Elements.BalconyArea />
    </div>
  </div>
)

/**
 */
export const Property = ({ propertyType, property }) => (
  <div className={styles.Property_Container}>
    <ImageSection />
    <InfoSection />
  </div>
)
