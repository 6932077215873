import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Context } from './Context'
import * as Renderers from 'atoms/Renderer'

import * as ImageHelper from 'utils/ImageHelper'

/**
 */
export const Consumer = ({ children }) => (
  <Context.Consumer>{({ property }) => children(property)}</Context.Consumer>
)

/**
 *
 */
export const Name = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ name: value = defaultValue }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const SideViewImage = ({
  render: Renderer = Renderers.ImageRenderer,
  default: defaultValue = null,
  ...props
}) => (
  <Consumer>
    {({ sideViewImage: src }) => {
      return (
        <Renderer
          value={{ src: ImageHelper.resolve(src), alt: '物件画像' }}
          {...props}
        />
      )
    }}
  </Consumer>
)
