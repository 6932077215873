// @flow
import { handleActions } from 'redux-actions'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { ACTION_PREFIX } from './consts'

export const initPlanState = {
  propertyType: null,
  property: null,
  roomPlan: null,
  colorSelection: null,
  roomOptionValues: {},
  estimation: {
    basecost: 0,
    additionalCost: 0,
    tax: 0,
    total: 0,
  },
}

export const initState = {
  isInitialized: false,
  entries: [],
  active: false,
}

/**
 * Root reducer
 */
export const reducers = persistReducer(
  {
    key: 'plan',
    storage: storage,
    whitelist: ['entries', 'active', 'isInitialized'],
  },
  handleActions(
    {
      INIT: {
        REQUEST: (state, action) => ({
          ...state,
          entries: [],
        }),
        SUCCESS: (state, action) => ({
          ...state,
          isInitialized: true,
        }),
      },
      ADD_ENTRY: {
        SUCCESS: (state, action) => ({
          ...state,
          entries: [...state.entries, action.payload].slice(-10),
          active: action.payload.id,
        }),
      },
      REMOVE_ENTRY: (state, action) => {
        const filtered = state.entries.filter(
          (entry) => entry.id !== action.payload.id,
        )

        return {
          ...state,
          entries: filtered,
          active: filtered.map((entry) => entry.id).includes(state.active)
            ? state.active
            : filtered.length > 0
            ? filtered[0].id
            : null,
        }
      },
      UPDATE_ENTRY: (state, action) => ({
        ...state,
        entries: [
          ...state.entries.filter(
            (entry) => entry.id !== action.payload.entry.id,
          ),
          action.payload.entry,
        ],
      }),
      SET_ACTIVE_ENTRY: (state, action) => ({
        ...state,
        active: action.payload.id,
      }),
      ESTIMATE: {
        SUCCESS: (state, action) => {
          return {
            ...state,
            entries: state.entries.map((entry) => {
              if (action.payload.plan === entry.id) {
                return {
                  ...entry,
                  estimation: action.payload.estimation,
                }
              }
              return entry
            }),
          }
        },
      },
    },
    initState,
    {
      prefix: ACTION_PREFIX,
    },
  ),
)
