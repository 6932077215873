import React from 'react'

import * as styles from './styles.module.scss'

export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

export const Title = ({ children }) => (
  <h2 className={styles.Title}>{children}</h2>
)

export const Description = ({ children }) => (
  <p className={styles.Description}>{children}</p>
)

export const Content = ({ children }) => (
  <div className={styles.Content}>{children}</div>
)
