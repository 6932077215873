// extracted by mini-css-extract-plugin
export var PropertyTypeImage_Container = "styles-module--PropertyTypeImage_Container--3BJJB";
export var PropertyTypeName_Container = "styles-module--PropertyTypeName_Container--327UP";
export var PropertyTypeName_Name = "styles-module--PropertyTypeName_Name--32W8M";
export var LayoutText = "styles-module--LayoutText--F7V5I";
export var LayoutSubText = "styles-module--LayoutSubText--3kpvp";
export var LayoutImage_Container = "styles-module--LayoutImage_Container--2_r0Y";
export var PropertyImage_Container = "styles-module--PropertyImage_Container--264OV";
export var Image = "styles-module--Image--3jjGt";
export var BalconyArea_Container = "styles-module--BalconyArea_Container--_Z3Dc";
export var BalconyArea_Value = "styles-module--BalconyArea_Value--cBYpb";
export var BalconyArea_Data = "styles-module--BalconyArea_Data--1JS7Q";
export var BalconyArea_DataTitle = "styles-module--BalconyArea_DataTitle--2G_tm";
export var OccupiedArea_Container = "styles-module--OccupiedArea_Container--1-e6s";
export var OccupiedArea_Value = "styles-module--OccupiedArea_Value--2Iwjb";
export var OccupiedArea_Data = "styles-module--OccupiedArea_Data--18Q-j";
export var OccupiedArea_DataTitle = "styles-module--OccupiedArea_DataTitle--1tKUy";
export var ModalButton = "styles-module--ModalButton--1y4U9";