import React from 'react'

import { Context } from '../Context'
import * as Reference from './Reference'

/**
 */
export const Each = ({ children }) => (
  <Context.Consumer>
    {({ room }) => {
      return room.optionGroups.map((optionGroup) => (
        <Reference.Provider optionGroup={optionGroup} key={optionGroup.title}>
          {children(optionGroup)}
        </Reference.Provider>
      ))
    }}
  </Context.Consumer>
)
