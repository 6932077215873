import React from 'react'

import * as Form from 'modules/plan/components/Form'
import * as Layout from '../Layout/Section'

import * as styles from './styles.module.scss'

export const SelectColorSelection = ({ onChange }) => (
  <Layout.Container>
    <Layout.Title>Color</Layout.Title>
    <Layout.Description>
      お部屋のカラーバリエーションをお選び下さい。
    </Layout.Description>
    <Layout.Content>
      <Form.Fields.ColorSelection styles={styles} />
    </Layout.Content>
  </Layout.Container>
)
