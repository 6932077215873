import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// Component imports
import * as actions from '../actions'
import * as selectors from '../selectors'

const DefaultLoading = () => <span>Loading All Rooms...</span>
/**
 */
const ReduxInitializer = connect(
  // mapStateToProps,
  (state) => ({
    isInitialized: selectors.selectIsInitialized(state),
  }),
  // mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        init: actions.init,
      },
      dispatch,
    ),
)(
  // BaseComponent
  class extends React.Component {
    componentDidMount = () => {
      const { init, data, isInitialized } = this.props

      if (!isInitialized) {
        // dispatch init
        init(data.rooms.nodes)
      }
    }

    render = () => {
      const {
        children,
        isInitialized,
        renderers: { Loading = DefaultLoading } = {},
      } = this.props

      if (!isInitialized) {
        return <Loading />
      }

      return <>{children}</>
    }
  },
)

/**
 * Initializer
 */
export const Initializer = (props) => {
  const data = useStaticQuery(graphql`
    query RoomsInitializerQuery {
      rooms: allRoomsYaml(sort: { fields: label }) {
        nodes {
          id
          key
          label
          roomPlan {
            id
            name
          }
          #area
          layout {
            image
          }
          vr {
            isEnabled
            imagePrefix
          }
          optionGroups {
            title
            options
          }
        }
      }
    }
  `)

  return <ReduxInitializer data={data} {...props} />
}
