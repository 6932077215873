// extracted by mini-css-extract-plugin
export var ContentsWrap = "styles-module--ContentsWrap--224Pk";
export var Content = "styles-module--Content--131Di";
export var TitleContainer = "styles-module--TitleContainer--uLOof";
export var Title = "styles-module--Title--1nIlP";
export var TitleText = "styles-module--TitleText--3mVBu";
export var TitleSmallText = "styles-module--TitleSmallText--2Jy9v";
export var Submit = "styles-module--Submit--15TpN";
export var Submit_Icon = "styles-module--Submit_Icon--3aPlJ";
export var ToVRButton = "styles-module--ToVRButton--_hDJ5";
export var ToVRButton_Icon = "styles-module--ToVRButton_Icon--29-Vr";
export var Information = "styles-module--Information--FNtn5";