import React from 'react'

import * as EstimationRef from 'modules/plan/components/Estimation'
import * as styles from './styles.module.scss'

/**
 */
const AmountName = ({ children }) => (
  <dt className={styles.AmountItem_Name}>{children}</dt>
)
const AmountCost = ({ children }) => (
  <dd className={styles.AmountItem_Cost}>{children}</dd>
)

const BaseAmount = () => (
  <dl className={styles.AmountItem}>
    <AmountName>物件価格</AmountName>
    <AmountCost>
      <EstimationRef.Properties.BaseCost />
    </AmountCost>
  </dl>
)

const AdditionalAmount = () => (
  <dl className={styles.AmountItem}>
    <AmountName>オプション価格</AmountName>
    <AmountCost>
      <EstimationRef.Properties.AdditionalTotal />
    </AmountCost>
    <div className={styles.AmountItem_Border} />
  </dl>
)

const TotalAmount = () => (
  <dl className={styles.TotalAmountItem}>
    <AmountName>
      合計価格<span className={styles.TotalAmountItem_NameSmall}>（税込）</span>
    </AmountName>
    <AmountCost>
      <EstimationRef.Properties.Total />
    </AmountCost>
  </dl>
)

const Summary = () => (
  <div className={styles.AmountsContainer}>
    <div className={styles.AmountsBorder}>
      <BaseAmount />
      <AdditionalAmount />
    </div>
    <TotalAmount />
  </div>
)

const DetailOpener = () => {
//  return (
//    <div className={styles.EstimateButton}>
//      現在のお見積もりを表示
//      <div className={styles.EstimateButton_Icon} />
//    </div>
//  )
  return (<></>)
}

export const Estimation = () => (
  <EstimationRef.Provider>
    <div className={styles.Container}>
      <div className={styles.Title}>現在のお見積もり</div>
      <Summary />
    </div>
  </EstimationRef.Provider>
)
