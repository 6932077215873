import React from 'react'

import * as PropertyType from 'modules/property_type/components/Reference'
import * as Property from 'modules/property/components/Reference'
import * as RoomPlan from 'modules/room_plan/components/Reference'

import * as styles from './styles.module.scss'

/**
 */
export const BalconyArea = () => (
  <dl className={styles.BalconyArea}>
    <dt className={styles.BalconyArea_DataTitle}>バルコニー面積 </dt>/
    <dd className={styles.BalconyArea_Data}>
      <PropertyType.Properties.BalconyTotalValue
        className={styles.BalconyArea_Value}
      />
      ㎡
    </dd>
  </dl>
)

/**
 */
export const OccupiedArea = () => (
  <dl className={styles.OccupiedArea}>
    <dt className={styles.OccupiedArea_DataTitle}>占有面積 </dt>/
    <dd className={styles.OccupiedArea_Data}>
      <PropertyType.Properties.AreaValue
        className={styles.OccupiedArea_Value}
      />
      ㎡
    </dd>
  </dl>
)

/**
 */
export const LayoutText = () => (
  <span>
    <RoomPlan.Properties.LayoutText default="" className={styles.LayoutText} />
    <RoomPlan.Properties.LayoutSubText
      default=""
      className={styles.LayoutSubText}
    />
  </span>
)

/**
 */
export const LayoutImage = () => (
  <div className={styles.LayoutImage_Container}>
    <RoomPlan.Properties.LayoutImage className={styles.Image} />
  </div>
)

/**
 */
export const PropertyTypeName = () => (
  <span className={styles.PropertyTypeName_Container}>
    <PropertyType.Properties.Name className={styles.PropertyTypeName_Name} />{' '}
    Type
  </span>
)

/**
 */
export const PropertyTypeImage = () => (
  <div className={styles.PropertyTypeImage_Container}>
    <PropertyType.Properties.SideViewImage className={styles.Image} />
  </div>
)

/**
 */
export const PropertyImage = () => (
  <div className={styles.PropertyImagen_Container}>
    <Property.Properties.SideViewImage className={styles.Image} />
  </div>
)
