import React from 'react'

import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

import { init } from '../actions'
import { selectInit } from '../selectors'

import { Page as Loading } from 'components/Loading'

const DefaultLoading = () => <Loading>storeを初期化中</Loading>

/**
 */
export const StateInitializer = connect(
  // mapStateToProps,
  (state) => ({
    init: selectInit(state),
  }),
  // mapDispatchToProps,
  (dispatch) => ({
    actions: bindActionCreators(
      {
        init,
      },
      dispatch,
    ),
  }),
)(
  // BaseComponent
  class Base extends React.Component {
    componentDidMount = () => {
      this.props.actions.init()
    }

    render = () => {
      const { init, children, loading: L = DefaultLoading } = this.props

      if (!init) {
        return <L />
      }

      return <>{children}</>
    }
  },
)
