import React from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from '../../actions'

/**
 */
const DefaultLinkRenderer = ({ id, remove, children, ...props }) => (
  <a
    onClick={(e) => {
      console.log('remove entry', id)
      remove(id)
    }}
    {...props}
  >
    {children}
  </a>
)

/**
 *
 */
export const RemoveEntry = connect(
  //
  null,
  //
  (dispatch) =>
    bindActionCreators(
      {
        remove: actions.removeEntry,
      },
      dispatch,
    ),
)(
  //
  ({ render: Renderer = DefaultLinkRenderer, remove, ...props }) => (
    <Renderer remove={remove} {...props} />
  ),
)
