import React from 'react'
import { connect } from 'react-redux'

import { Context } from './Context'
import * as selectors from '../../selectors'

/**
 */
export const Provider = connect((state, props) => ({
  estimation: props.estimation
    ? props.estimation
    : !!props.plan
    ? selectors.selectEntryById(state)(props.plan).estimation
    : selectors.selectActiveEntry(state).estimation,
}))(({ estimation, children }) => (
  <Context.Provider value={{ estimation }}>{children}</Context.Provider>
))
