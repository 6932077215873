import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Context } from '../Context'
import * as Renderers from 'atoms/Renderer'

/**
 *
 */
export const Label = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => {
  const {
    value: { label: value = defaultValue },
  } = React.useContext(Context)

  return <Renderer {...props} value={value} />
}

/**
 *
 */
export const Code = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => {
  const {
    value: { productCode: value = defaultValue },
  } = React.useContext(Context)

  return <Renderer {...props} value={value} />
}

/**
 */
export const LabelOrCode = () => {
  const {
    value: { code = null, label = null },
  } = React.useContext(Context)

  if (label) {
    return <Label />
  } else {
    return <Code />
  }
}

/**
 *
 */
export const Description = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => {
  const {
    value: { description: value = defaultValue },
  } = React.useContext(Context)

  return <Renderer {...props} value={value} />
}

/**
 *
 */
export const Cost = ({
  render: Renderer = Renderers.PriceRenderer,
  default: defaultValue = 0,
  ...props
}) => {
  const {
    value: { cost: { fixed: value = defaultValue } = {}},
  } = React.useContext(Context)

  return <Renderer {...props} value={value} />
}
