export const selectModule = (state) => state.module.propertyType

export const selectIsInitialized = (state) => selectModule(state).init

export const selectEntries = (state) => selectModule(state).entries

export const selectEntryByName = (state) => (name) => {
  return selectEntries(state).find((v) => v.name === name)
}

export const selectDefault = (state) => selectModule(state).default
