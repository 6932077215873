// extracted by mini-css-extract-plugin
export var Section = "styles-module--Section--21yCQ";
export var Building_LandImageSection = "styles-module--Building_LandImageSection--QgzrZ";
export var Building_ImageSection = "styles-module--Building_ImageSection--1pBJN";
export var PropertyType_LayoutSection = "styles-module--PropertyType_LayoutSection--1mvDP";
export var PropertyType_ImageSection = "styles-module--PropertyType_ImageSection--2rYoJ";
export var PropertyTypeImageSection_Container = "styles-module--PropertyTypeImageSection_Container--ie9xq";
export var Property_ImageSection = "styles-module--Property_ImageSection--61VCp";
export var Property_InfoSection = "styles-module--Property_InfoSection--2xIBe";
export var RoomPlan_ImageSection = "styles-module--RoomPlan_ImageSection--3viEB";
export var RoomPlan_InfoSection = "styles-module--RoomPlan_InfoSection--1w21G";
export var Building_Container = "styles-module--Building_Container--1Xw0T";
export var PropertyType_Container = "styles-module--PropertyType_Container--1ooUF";
export var PropertyTypeNameSection_Container = "styles-module--PropertyTypeNameSection_Container--34TW7";
export var PropertyTypeNameSection_Name = "styles-module--PropertyTypeNameSection_Name--weOo5";
export var LayoutImageSection_Container = "styles-module--LayoutImageSection_Container--3o8QD";
export var PropertyImageSection_Container = "styles-module--PropertyImageSection_Container--1Dmov";
export var Property_Container = "styles-module--Property_Container--2LaL1";
export var Property_LayoutInfo = "styles-module--Property_LayoutInfo--IjhdW";
export var Property_LayoutSection = "styles-module--Property_LayoutSection--2P4r1";
export var RoomPlan_Container = "styles-module--RoomPlan_Container--3EuQE";
export var Image = "styles-module--Image--2ixkJ";
export var BalconyAreaSection_Container = "styles-module--BalconyAreaSection_Container--Hmv_5";
export var BalconyAreaSection_Value = "styles-module--BalconyAreaSection_Value--2K4dX";
export var BalconyAreaSection_Data = "styles-module--BalconyAreaSection_Data--BiAEd";
export var BalconyAreaSection_DataTitle = "styles-module--BalconyAreaSection_DataTitle--7G62Q";
export var OccupiedAreaSection_Container = "styles-module--OccupiedAreaSection_Container--20GK9";
export var OccupiedAreaSection_Value = "styles-module--OccupiedAreaSection_Value--2JJMk";
export var OccupiedAreaSection_Data = "styles-module--OccupiedAreaSection_Data--1Lav9";
export var OccupiedAreaSection_DataTitle = "styles-module--OccupiedAreaSection_DataTitle--1FVjH";
export var PropertyImageSectionn_Container = "styles-module--PropertyImageSectionn_Container--1sIxv";
export var ModalButton = "styles-module--ModalButton--1frNS";
export var Layout_Image = "styles-module--Layout_Image--1HMpM";