import React from 'react'
import numeral from 'numeral'

import { Context } from '../Context'

const Consumer = ({ children }) => (
  <Context.Consumer>
    {({ estimation }) => children(estimation)}
  </Context.Consumer>
)

/**
 */
const DefaultCurrencyRenderer = ({ value, format = '0,0', ...props }) => (
  <span {...props}>{numeral(value).format(format)}円</span>
)

/**
 */
export const BaseCost = ({
  render: Renderer = DefaultCurrencyRenderer,
  ...props
}) => (
  <Consumer>
    {({ basecost: value }) => {
      return <Renderer value={value} {...props} />
    }}
  </Consumer>
)

/**
 */
export const AdditionalTotal = ({
  render: Renderer = DefaultCurrencyRenderer,
  ...props
}) => (
  <Consumer>
    {({ additionalCost: value }) => {
      return <Renderer value={value} {...props} />
    }}
  </Consumer>
)

/**
 */
export const Total = ({
  render: Renderer = DefaultCurrencyRenderer,
  ...props
}) => (
  <Consumer>
    {({ total: value }) => <Renderer value={value} {...props} />}
  </Consumer>
)

/**
 */
export const Tax = ({
  render: Renderer = DefaultCurrencyRenderer,
  ...props
}) => (
  <Consumer>
    {({ tax: value }) => <Renderer value={value} {...props} />}
  </Consumer>
)
