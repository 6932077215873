import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'

import * as Reference from './Reference'

/**
 *
 */
export const Each = connect(
  // mapStateToProps
  (state) => ({
    propertyTypes: selectors.selectEntries(state),
  }),
)(
  // BaseComponent
  ({ propertyTypes, children, filter = () => true }) => {
    return propertyTypes
      .filter(filter)
      .map((propertyType) => (
        <Reference.Provider name={children.name}>{children}</Reference.Provider>
      ))
  },
)
