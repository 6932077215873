import React from 'react'
import { StyleConsumer } from './StyleContext'
import * as FormLayout from 'layouts/FormLayout'
import * as HeaderFooterLayout from 'layouts/HeaderFooterLayout'

export const Header = HeaderFooterLayout.Header

export const Footer = HeaderFooterLayout.Footer

export const Body = HeaderFooterLayout.Body

export const Field = ({ className, children }) => (
  <StyleConsumer>
    {({ styles }) => (
      <FormLayout.Field className={className ? className : styles.Layout_Field}>
        {children}
      </FormLayout.Field>
    )}
  </StyleConsumer>
)

export const Label = ({ className, children }) => (
  <StyleConsumer>
    {({ styles }) => (
      <FormLayout.Label
        className={className ? className : styles.Layout_FieldLabel}
      >
        {children}
      </FormLayout.Label>
    )}
  </StyleConsumer>
)

export const Input = ({ className, children }) => (
  <StyleConsumer>
    {({ styles }) => (
      <FormLayout.Input
        className={className ? className : styles.Layout_FieldInput}
      >
        {children}
      </FormLayout.Input>
    )}
  </StyleConsumer>
)

export const FieldGroup = ({ className, children }) => (
  <StyleConsumer>
    {({ styles }) => (
      <FormLayout.Field
        className={className ? className : styles.Layout_FieldGroup}
      >
        {children}
      </FormLayout.Field>
    )}
  </StyleConsumer>
)

export const FieldGroupLabel = ({ className, children }) => (
  <StyleConsumer>
    {({ styles }) => (
      <FormLayout.Label
        className={className ? className : styles.Layout_FieldGroupLabel}
      >
        {children}
      </FormLayout.Label>
    )}
  </StyleConsumer>
)

export const FieldGroupBody = ({ className, children }) => (
  <StyleConsumer>
    {({ styles }) => (
      <FormLayout.Input
        className={className ? className : styles.Layout_FieldGroupBody}
      >
        {children}
      </FormLayout.Input>
    )}
  </StyleConsumer>
)
