import React from 'react'

import { Provider as OptionValueProvider } from 'modules/option/components/Value/Provider'
import { Context as PlanContext } from '../Context'

/**
 * Get all selected options
 */
export const Each = ({ children }) => {
  const {
    plan: { roomOptionValues },
  } = React.useContext(PlanContext)

  return Object.entries(roomOptionValues).map(([id, { option, value }]) => (
    <OptionValueProvider key={id} value={value}>
      {children(value)}
    </OptionValueProvider>
  ))
}
