import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// Import dependencies
import { FormName, Field, formValueSelector, clearFields } from 'redux-form'
import * as Inputs from 'atoms/Inputs'

import * as PropertyTypes from 'modules/property_type/components'
import * as Properties from 'modules/property/components'
import * as RoomPlans from 'modules/room_plan/components'
import * as ColorSelections from 'modules/color_selection/components'

// Import locals
import { StyleConsumer } from './StyleContext'

/**
 */
export const PropertyType = ({
  render: Renderer = Inputs.Choices,
  onChange,
  ...props
}) => (
  <StyleConsumer>
    {({ styles }) => (
      <PropertyTypes.All>
        {(propertyTypes) => (
          <Field
            name="propertyType"
            component={Renderer}
            options={propertyTypes.map((propertyType) => ({
              id: `propertyType_${propertyType.name}`,
              value: propertyType.name,
              label: propertyType.label,
            }))}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value)
              }
            }}
            {...props}
          />
        )}
      </PropertyTypes.All>
    )}
  </StyleConsumer>
)

/**
 */
export const Property = ({ ...props }) => (
  <FormName>
    {({ form }) => <ConnectedProperty form={form} {...props} />}
  </FormName>
)

const ConnectedProperty = connect(
  (state, props) => ({
    propertyType: formValueSelector(props.form)(state, 'propertyType'),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        clearFields,
      },
      dispatch,
    ),
)(
  ({
    render: Renderer = Inputs.Choices,
    propertyType = null,
    clearFields,
    form,
    ...props
  }) => {
    React.useEffect(() => {
      clearFields(form, false, false, 'property', 'roomPlan')
    }, [propertyType])

    if (propertyType) {
      return (
        <StyleConsumer>
          {({ styles }) => (
            <Properties.All propertyType={propertyType}>
              {(properties) => (
                <Field
                  name="property"
                  component={Renderer}
                  options={properties.map((property) => ({
                    id: `property_${property.key}`,
                    value: property.key,
                    label: property.name,
                  }))}
                  {...props}
                />
              )}
            </Properties.All>
          )}
        </StyleConsumer>
      )
    }

    return null
  },
)

/**
 */
export const RoomPlan = ({ ...props }) => (
  <FormName>
    {({ form }) => <ConnectedRoomPlan form={form} {...props} />}
  </FormName>
)

/**
 */
const ConnectedRoomPlan = connect((state, props) => {
  const selector = formValueSelector(props.form)
  return {
    propertyType: selector(state, 'propertyType'),
    property: selector(state, 'property'),
  }
})(
  ({
    render: Renderer = Inputs.Choices,
    propertyType = null,
    property = null,
    ...props
  }) => {
    if (propertyType && property) {
      return (
        <StyleConsumer>
          {({ styles }) => (
            <RoomPlans.All propertyType={propertyType}>
              {(roomPlans) => (
                <Field
                  name="roomPlan"
                  component={Renderer}
                  options={roomPlans.map((roomPlan) => ({
                    id: `roomPlan_${roomPlan.id}`,
                    value: roomPlan.id,
                    label: roomPlan.label,
                  }))}
                  {...props}
                />
              )}
            </RoomPlans.All>
          )}
        </StyleConsumer>
      )
    }

    return null
  },
)

const ColorSelectionRenderChoice = ({
  id,
  checked,
  value,
  input,
  label,
  styles: overwriteStyles,
  labelStyle,
}) => (
  <StyleConsumer styles={overwriteStyles}>
    {({ styles }) => (
      <label
        className={checked ? styles.Choice_Label_Checked : styles.Choice_Label}
        style={labelStyle}
      >
        <input
          id={id}
          className={styles.Choice_Input}
          type="radio"
          {...input}
          value={`${value}`}
          checked={checked}
          onChange={(e) => input.onChange(e)}
        />
        {label}
      </label>
    )}
  </StyleConsumer>
)

/**
 */
export const ColorSelection = ({ ...props }) => (
  <FormName>
    {({ form }) => <ConnectedColorSelection form={form} {...props} />}
  </FormName>
)

const ConnectedColorSelection = connect((state, props) => {
  const selector = formValueSelector(props.form)
  return {
    roomPlan: selector(state, 'roomPlan'),
  }
})(({ render: Renderer = Inputs.Choices, roomPlan, ...props }) => {
  if (roomPlan) {
    return (
      <StyleConsumer>
        {({ styles }) => (
          <ColorSelections.All>
            {(colors) => (
              <Field
                name="colorSelection"
                component={Renderer}
                options={colors.map((color) => ({
                  id: `color_${color.id}`,
                  value: color.id,
                  label: color.label,
                  labelStyle: {
                    background: color.colors.background,
                    color: color.colors.font,
                  },
                }))}
                renderChoice={ColorSelectionRenderChoice}
                {...props}
              />
            )}
          </ColorSelections.All>
        )}
      </StyleConsumer>
    )
  }

  return null
})
