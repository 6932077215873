import React from 'react'

import * as Modal from 'atoms/Modal'
import * as styles from './styles.module.scss'

import * as PropertyType from 'modules/property_type/components/Reference'
import * as RoomPlan from 'modules/room_plan/components/Reference'

import * as Elements from './Elements'

/**
 *
 */
const CloseButton = () => {
  const { closeModal } = React.useContext(Modal.Context)
  return (
    <button
      type="button"
      onClick={() => {
        closeModal()
      }}
      className={styles.CloseButton}
    ></button>
  )
}

/**
 */
const Container = ({ children }) => (
  <Modal.Content>
    <div className={styles.LayoutInfoModal}>{children}</div>
  </Modal.Content>
)

/**
 */
const Body = () => (
  <div className={styles.Body_Container}>
    <Description />
    <div className={styles.Image_Container}>
      <Image />
    </div>
  </div>
)

const Description = () => (
  <div className={styles.Description_Container}>
    <Elements.PropertyTypeName />
    <Elements.LayoutText />
    <Elements.OccupiedArea />
    <Elements.BalconyArea />
  </div>
)

/**
 */
const Header = () => <CloseButton />

/**
 */
const Image = () => {
  const { image } = React.useContext(ImageContext)
  return <img className={styles.Image} src={image} />
}

/**
 */
export const Content = ({ image }) => {
  return (
    <ImageProvider image={image}>
      <Container>
        <Header />
        <Body />
      </Container>
    </ImageProvider>
  )
}

const ImageContext = React.createContext()
const ImageProvider = ({ image, children }) => (
  <ImageContext.Provider value={{ image }}>{children}</ImageContext.Provider>
)
