import { all, takeLeading, put } from 'redux-saga/effects'

import { actionName, info } from './utils'
import * as actions from './actions'

/**
 */
function* handleInit(action) {
  try {
    info(action)
    yield put(actions.initSuccess())
  } catch (e) {
    yield put(actions.initFailure(e))
  }
}

/**
 */
export function* sagas() {
  yield all([takeLeading(actionName('/INIT/REQUEST'), handleInit)])
}
