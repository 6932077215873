import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'
import { filterByPropertyType } from '../utils'

export const Base = ({ properties, children, propertyType }) => {
  return children(properties.filter(filterByPropertyType(propertyType)))
}

export const All = connect((state) => ({
  properties: selectors.selectEntries(state),
}))(Base)
