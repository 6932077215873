import React from 'react'


import * as FormComponents from 'modules/plan/components/Form'
import { SelectPropertyType } from './PropertyType'
import { SelectProperty } from './Property'
import { SelectRoomPlan } from './RoomPlan'
import { SelectColorSelection } from './ColorSelection'
import { Preview } from './Preview'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children, ...props}) => {
  return (
    <FormComponents.AddForm
      styles={styles}
      {...props}
    >
      {children}
    </FormComponents.AddForm>
  )
}
