import React from 'react'

import * as Renderers from 'atoms/Renderer'
import { Context } from '../Context'
import * as ImageHelper from 'utils/ImageHelper'

/**
 * Local consumer to extract building
 */
const Consumer = ({ children }) => (
  <Context.Consumer>
    {({ building }) => {
      return children(building)
    }}
  </Context.Consumer>
)

/**
 *
 */
export const NameJa = ({
  render: Renderer = Renderers.TextRenderer,
  ...props
}) => (
  <Consumer>
    {({ name: { ja: value } }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const NameEn = ({
  render: Renderer = Renderers.TextRenderer,
  ...props
}) => (
  <Consumer>
    {({ name: { en: value } }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const SubName = ({
  render: Renderer = Renderers.TextRenderer,
  ...props
}) => (
  <Consumer>
    {({ subname: value }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const Address = ({
  render: Renderer = Renderers.TextRenderer,
  ...props
}) => (
  <Consumer>
    {({ address: value }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const TopViewImage = ({
  render: Renderer = Renderers.ImageRenderer,
  index = 0,
  ...props
}) => (
  <Consumer>
    {({ topViewImage: value }) => {
      return <Renderer {...props} value={{ src: ImageHelper.resolve(value) }} />
    }}
  </Consumer>
)

/**
 *
 */
export const SideViewImage = ({
  render: Renderer = Renderers.ImageRenderer,
  index = 0,
  ...props
}) => (
  <Consumer>
    {({ sideViewImage: value }) => {
      return (
        <Renderer
          {...props}
          value={{ src: ImageHelper.resolve(value) }}
        />
      )
    }}
  </Consumer>
)
