import React from 'react'

import { Initializer } from './Initializer'

/**
 */
export const RootWrapper = ({ element }) => {
  return <Initializer usePersistor={ true }>{element}</Initializer>
}

