import * as ImageHelper from 'utils/ImageHelper'
import urljoin from "url-join"

/**
 */
export const vrPath = (
  roomKey,
  colorSelectionId,
  options = [],
  filename = 'room.jpg',
) =>
  ImageHelper.resolve(
    '/rooms',
    roomKey,
    `c_${colorSelectionId}`,
    createVrOptionPath(options),
    'room.jpg',
  )

/**
 * [[key, value], [key, value]...]
 */
const createVrOptionPath = (options = []) => {
  return urljoin(
    options.map(([key, value]) => `${key}_${value}`)
  )
}
