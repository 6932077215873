/**
 */
export const selectModule = (state) => state.module.option

/**
 */
export const selectIsInitialized = (state) => selectModule(state).init

/**
 */
export const selectEntries = (state) => selectModule(state).entries

/**
 */
export const selectEntryById = (state) => (id) =>
  selectModule(state).entries.find((v) => v.id === id)
