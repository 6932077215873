import React from 'react'

import * as Elements from './Elements'

import * as styles from './styles.module.scss'

/**
 */
const ImageSection = () => (
  <div className={styles.PropertyType_ImageSection}>
    <Elements.PropertyTypeImage className={styles.Image} />
  </div>
)

/**
 */
const LayoutSection = () => (
  <div className={styles.PropertyType_LayoutSection}>
    <Elements.PropertyTypeName/>
    <Elements.OccupiedArea/>
    <Elements.BalconyArea/>
  </div>
)

/**
 */
export const PropertyType = ({ propertyType }) => (
  <div className={styles.PropertyType_Container}>
    <ImageSection />
    <LayoutSection />
  </div>
)
