import React from 'react'

import { Consumer as ReferenceConsumer } from '../Context'
import * as Renderers from 'atoms/Renderer'

const Consumer = ({ children }) => (
  <ReferenceConsumer>{({ plan }) => children(plan)}</ReferenceConsumer>
)

/**
 *
 */
export const Name = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ name: value = defaultValue }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)
