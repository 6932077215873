import React from 'react'

import * as styles from './styles.module.scss'
import * as Option from 'modules/option/components/Reference'
import { RoomOptionValues } from './RoomOptionValues'

const Container = ({ children }) => <dl className={styles.Item}>{children}</dl>

/**
 *
 */
const Title = ({ isExpanded, setExpanded }) => (
  <dt className={styles.Item_Input} onClick={(e) => setExpanded(!isExpanded)}>
    <span
      className={
        isExpanded ? styles.Item_Checkbox_Checked : styles.Item_Checkbox
      }
    />
    <span className={styles.Item_Label}>
      <Option.Properties.Label />
    </span>
    <Option.IsVrEnabled>
      <span className={ styles.VR_ENABLED}>VR</span>
    </Option.IsVrEnabled>
  </dt>
)

/**
 */
const Description = () => (
  <div className={styles.Item_Detail_Text}>
    <Option.Properties.Description />
  </div>
)

/**
 */
const OptionDetail = ({ isExpanded }) => (
  <dd className={isExpanded ? styles.Item_Content_Opened : styles.Item_Content}>
    <div className={styles.Item_Detail}>
      <Description />
      <RoomOptionValues />
    </div>
  </dd>
)

/**
 */
export const RoomOption = () => {
  const [isExpanded, setExpanded] = React.useState(true)

  return (
    <Container>
      <Title isExpanded={isExpanded} setExpanded={setExpanded} />
      <OptionDetail isExpanded={isExpanded} />
    </Container>
  )
}
