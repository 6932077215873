import React from 'react'

import * as Plan from 'modules/plan/components/Reference'
import * as RoomPlan from 'modules/room_plan/components/Reference'
import * as Plans from 'modules/plan/components'

import * as styles from './styles.module.scss'
import * as Modal from 'atoms/Modal'
import * as AddPlanForm from 'components/AddPlanForm'
import * as Links from 'components/Links'

/**
 */
const PropertyList = () => {
  const [isVisible, setVisible] = React.useState(false)
  return (
    <>
      <div className={styles.ExpandButtonContainer}>
        <a
          className={styles.ExpandButton}
          onClick={(e) => setVisible(!isVisible)}
        >
          他の部屋を見る
          <div
            className={
              isVisible
                ? styles.ExpandButtonIcon_Opened
                : styles.ExpandButtonIcon_Closed
            }
          />
        </a>
      </div>
      <div
        className={
          isVisible ? styles.Accordion_Opened : styles.Accordion_Closed
        }
      >
        <ul>
          <Plan.Consumer>
            {({ plan }) => (
              <Plans.Each filter={(p) => p.id !== plan.id}>
                {(plan) => (
                  <li
                      className={styles.RoomPlanItem}
                  >
                    <Plans.Actions.ChangeActive
                      id={plan.id}
                      className={ styles.RoomPlanItem_Link }
                    >
                      <div className={styles.RoomPlanItem_Dod} />
                      <Plan.Properties.Name
                        className={styles.RoomPlanItem_Name}
                      />
                      <div className={styles.RoomPlanItem_Layout}>
                        <RoomPlan.Properties.LayoutText />
                        <RoomPlan.Properties.LayoutSubText />
                      </div>
                    </Plans.Actions.ChangeActive>
                    <Plans.Actions.RemoveEntry id={plan.id}>
                      <div
                        className={styles.RoomPlanItemIcon_Delete}
                      />
                    </Plans.Actions.RemoveEntry>
                  </li>
                )}
              </Plans.Each>
            )}
          </Plan.Consumer>
        </ul>
        <Modal.Consumer>
          {({ openModal }) => (
            <a
              className={styles.RoomPlanItem}
              onClick={() => openModal(<AddPlanForm.Modal.Content />)}
            >
              <div className={styles.RoomPlanItemIcon_Add} />
              お部屋を追加
            </a>
          )}
        </Modal.Consumer>
        <Links.ToTopPage className={styles.RoomPlanItem}>
          トップページへ戻る
        </Links.ToTopPage>
        <div className={styles.RoomPlanItemLast} />
      </div>
    </>
  )
}

export const Header = () => {
  return (
    <div className={styles.Container}>
      <Plan.Properties.Name className={styles.Name} />
      <PropertyList />
    </div>
  )
}
