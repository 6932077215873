import React from 'react'

import { Context as RoomContext } from './Context'

const Context = React.createContext({ optionGroups: [] })

const Provider = ({ children }) => {
  return (
    <RoomContext.Consumer>
      {({ room }) => {
        return (
          <Context.Provider
            value={{ optionGroups: room.optionGroups ? room.optionGroups : [] }}
          >
            {children}
          </Context.Provider>
        )
      }}
    </RoomContext.Consumer>
  )
}

const Each = ({ children }) => (
  <Context.Consumer>
    {({ optionGroups }) => {
      const rendered = optionGroups.map((group, idx) => children(group, idx))

      return rendered
    }}
  </Context.Consumer>
)

export const OptionGroups = {
  Provider,
  Each,
}
