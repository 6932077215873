import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'

/*
 */
export const All = connect(
  // mapStateToProps
  (state) => ({
    rooms: selectors.selectEntries(state),
  }),
)(
  // BaseComponent
  ({ rooms, children, filter = () => true }) => {
    return children(rooms.filter(filter))
  },
)
