import React from 'react'
import * as styles from './default.module.scss'

/**
 *
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
export const Contents = ({ children }) => (
  <div className={styles.Contents}>{children}</div>
)

/**
 */
export const Information = ({ children }) => (
  <div className={styles.Information}>{children}</div>
)
