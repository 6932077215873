import React from 'react'

import * as Base from 'modules/room/components'
import { Context, Consumer } from '../Context'

/**
 */
export const All = (props) => {
  const { roomPlan } = React.useContext(Context)
  if (!roomPlan) {
    return <>ルームプランが選択されていません。</>
  }

  return (
    <Base.All {...props} filter={(room) => roomPlan.rooms.includes(room.id)} />
  )
}

/**
 */
export const Each = (props) => {
  const { roomPlan } = React.useContext(Context)

  if (!roomPlan) {
    return <>ルームプランが選択されていません。</>
  }

  return (
    <Base.Each {...props} filter={(room) => roomPlan.rooms.includes(room.id)} />
  )
}
