import React from 'react'

import * as styles from './styles.module.scss'

import * as OptionGroup from 'modules/room/components/Reference/OptionGroups/Reference'
import { RoomOptions } from './RoomOptions'

/**
 */
const Container = ({ children }) => (
  <dl className={styles.Container}>{children}</dl>
)

/**
 */
const Title = ({ isExpanded, setExpanded }) => (
  <dt
    className={isExpanded ? styles.Title_Opened : styles.Title}
    onClick={(e) => setExpanded(!isExpanded)}
  >
    <span className={styles.Title_Label}>
      <OptionGroup.Properties.Title />
    </span>
    <span
      className={isExpanded ? styles.Title_Arrow_Opened : styles.Title_Arrow}
    />
  </dt>
)

/**
 */
const Options = ({ isExpanded }) => (
  <dd className={isExpanded ? styles.Content : styles.Content_Closed}>
    <div className={styles.Content_ItemContainer}>
      <RoomOptions />
    </div>
  </dd>
)

/**
 */
export const RoomOptionGroup = () => {
  const [isExpanded, setExpanded] = React.useState(true)

  return (
    <Container>
      <Title isExpanded={isExpanded} setExpanded={setExpanded} />
      <Options isExpanded={isExpanded} />
    </Container>
  )
}
