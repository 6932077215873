import React from 'react'

import * as Form from 'modules/plan/components/Form'
import * as Layout from '../Layout/Section'

import * as styles from './styles.module.scss'

/**
 */
export const SelectRoomPlan = ({ propertyType }) => (
  <Layout.Container>
    <Layout.Title>Plan</Layout.Title>
    <Layout.Description>お部屋の拡張プランをお選び下さい。</Layout.Description>
    <Layout.Content>
      <Form.Fields.RoomPlan styles={styles} />
    </Layout.Content>
  </Layout.Container>
)
