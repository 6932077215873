import React from 'react'

import * as Layout from 'layouts/ErrorPageLayout'
import * as styles from './styles.module.scss'
import * as Links from 'components/Links'

const NotFoundPage = () => (
  <Layout.Container>
    <Layout.ErrorCode>404</Layout.ErrorCode>
    <Layout.Title>NOT FOUND</Layout.Title>
    <Layout.Description>
      申し訳ございません。 お探しのページはアクセスできない状況にあるか、
      <br />
      移動もしくは削除された可能性があります。
    </Layout.Description>
    <Layout.Navi>
      <Links.ToTopPage className={styles.TopPageLink}>
        トップページへ戻る &gt;
      </Links.ToTopPage>
    </Layout.Navi>
  </Layout.Container>
)

export default NotFoundPage
