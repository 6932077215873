import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'

/**
 *
 */
export const All = connect(
  // mapStateToProps
  (state) => ({
    colorSelections: selectors.selectEntries(state),
  }),
)(
  // BaseComponent
  ({ children, colorSelections }) => children(colorSelections),
)
