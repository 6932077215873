import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'
import { Provider as ReferenceProvider } from './Reference'

const ChildrenRenderer = ({ children }) => children

const DefaultCustomFilter = (entry) => true

/**
 *
 */
export const Each = connect(
  // mapStateToProps
  (state) => ({
    colorSelections: selectors.selectEntries(state),
  }),
)(
  // BaseComponent
  ({
    render: Renderer  = ChildrenRenderer,
    colorSelections,
    children,
    filter = DefaultCustomFilter,
  }) => {

    return colorSelections.filter(filter).map((colorSelection) => (
      <ReferenceProvider
        key={colorSelection.id}
        colorSelection={colorSelection}
      >
        <Renderer colorSelection={ colorSelection }>{children}</Renderer>
      </ReferenceProvider>
    ))
  },
)
