import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../../selectors'

/**
 */
export const Active = connect(
  //
  (state) => ({
    active: selectors.selectActive(state),
  }),
)(
  //
  ({ active, children }) => {
    return children(active)
  },
)
