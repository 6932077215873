import React from 'react'
// Import dependencies
import numeral from 'numeral'
import * as moment from 'moment'
import 'moment/locale/ja'

import * as DefaultStyles from './styles.module.scss'

const DefaultFormatter = (v) => v

/**
 *
 */
export const TextRenderer = ({
  value,
  className,
  format = DefaultFormatter,
}) => {
  return <span className={className}>{format(value)}</span>
}

/**
 *
 */
export const ImageRenderer = ({ className, value, styles = {} }) => (
  <img
    src={value.src ? value.src : '/images/noimage.png'}
    alt={value.alt}
    className={className ? className : styles.Image}
  />
)

/**
 *
 */
export const ListRenderer = ({ className, value, ...props }) => {
  const rows = value.map((v, idx) => <li key={idx}>{v}</li>)
  return <ul className={className}>{rows}</ul>
}

/**
 * Return just as an array
 */
export const LinearRenderer = ({ value }) => {
  return <>{value}</>
}

/**
 *
 */
export const LinkRenderer = ({
  value,
  className,
  styles = DefaultStyles,
  format = DefaultFormatter,
  children,
}) => (
  <a href={value} className={className ? className : styles.Link}>
    {children ? children : format(value)}
  </a>
)

/**
 * Renderer to render collection items
 */
export const ArrayRenderer = ({
  value,
  render: Renderer = LinearRenderer,
  renderItem: ItemRenderer = TextRenderer,
  styles = DefaultStyles,
  ...props
}) => {
  const children = value.map((v, idx) => (
    <ItemRenderer key={idx} index={idx} value={v} styles={styles} {...props} />
  ))

  return <Renderer value={children} styles={styles} {...props} />
}

export const DateRenderer = ({
  value,
  className,
  format = 'YYYY/MM/DD',
  styles = {},
}) => (
  <span className={className ? className : styles.Date}>
    {moment(value).format(format)}
  </span>
)

export const DateTimeRenderer = ({
  value,
  className,
  format = 'YYYY/MM/DD HH:mm',
  styles = {},
}) => (
  <span className={className ? className : styles.DateTime}>
    {moment(value).format(format)}
  </span>
)

const DefaultPriceFormatter = ({ value, format, prefix, suffix }) => {
  return `${prefix ?? ''}${numeral(value).format(format)}${suffix ?? ''}`
}

export const PriceRenderer = ({
  className,
  value,
  formatter = DefaultPriceFormatter,
  format = '0,0',
  styles = {},
  ...props
}) => (
  <span className={className ? className : styles.Price}>
    {formatter({ value, format, ...props })}
  </span>
)

/**
 */
export const NumberRenderer = ({ value, styles = DefaultStyles, ...props }) => {
  const [i, d] = numeral(value).format('0,0.00').split('.')
  return (
    <span {...props}>
      <span className={styles.Integer}>{i}</span>
      <span className={styles.Dot}>.</span>
      <span className={styles.Decimal}>{d}</span>
    </span>
  )
}
