import React from 'react'

import { Context } from './Context'
import * as Renderers from 'atoms/Renderer'
import * as ImageHelper from 'utils/ImageHelper'

const Consumer = ({ children, loading: Loading = () => <>Loading...</> }) => (
  <Context.Consumer>
    {({ isLoading, roomPlan }) => {
      if (!roomPlan) {
        if (isLoading) {
          // Loading
          return <Loading />
        }

        return <></>
      }

      return <>{children(roomPlan)}</>
    }}
  </Context.Consumer>
)

/**
 * Name property
 */
export const Label = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ label: value }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 * RoomLayout
 */
export const LayoutText = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ layout: { text: value } }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 * NonRoomLayout
 */
export const LayoutSubText = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ layout: { subtext: value } }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)

/**
 *
 */
export const LayoutImage = ({
  render: Renderer = Renderers.ImageRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ layout: { image: value } }) => {
      return (
        <Renderer
          {...props}
          value={{ src: ImageHelper.resolve(value), alt: 'Layout' }}
        />
      )
    }}
  </Consumer>
)

export const VRMessage = ({
  render: Renderer = Renderers.TextRenderer,
  default: defaultValue = '',
  ...props
}) => (
  <Consumer>
    {({ vr: { message: value } }) => {
      return <Renderer {...props} value={value} />
    }}
  </Consumer>
)
