import React from 'react'

import { Header } from './Header'
import { SelectRoom } from './SelectRoom'
import { Selector as SelectColorSelector } from './SelectColor'
import { ActiveRoomOptions } from './ActiveRoomOptions'
import { Estimation } from './Estimation'

import * as styles from './styles.module.scss'

const SelectColor = () => (
  <div>
    <SelectColorSelector/>
  </div>
)

/**
 */
export const Menu = ({ className }) => (
  <div className={className ? className : styles.Container}>
    <Header />
    <SelectColor/>
    <SelectRoom />
    <ActiveRoomOptions />
    <Estimation />
  </div>
)
