import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../actions'

/**
 * ```
 * <SelectOption>{ ({ selectOption }) => (
 *   <button onClick={ () => selectOption() }></button>
 * )}</SelectOption>
 * ```
 */
export const SelectOption = connect(
  // mapStateToProps
  null,
  // mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        setRoomOption: actions.setRoomOption,
      },
      dispatch,
    ),
)(
  // BaseComponent
  ({ setRoomOption, plan = null, children }) => {
    const selectOption = (id, value) => {
      setRoomOption(id, value, plan)
    }

    return children({ selectOption })
  },
)
