import React from 'react'

import { connect } from 'react-redux'
import { FormName, formValueSelector } from 'redux-form'

import * as PropertyType from 'modules/property_type/components/Reference'
import * as Property from 'modules/property/components/Reference'
import * as RoomPlan from 'modules/room_plan/components/Reference'

/**
 *
 */
const ConnectedPreview = connect((state, props) => {
  const selector = formValueSelector(props.form)
  return {
    propertyType: selector(state, 'propertyType'),
    property: selector(state, 'property'),
    roomPlan: selector(state, 'roomPlan'),
  }
})(
  ({
    propertyType,
    property,
    roomPlan,
    renderBuildingPreview: BuildingPreview,
    renderPropertyTypePreview: PropertyTypePreview,
    renderPropertyPreview: PropertyPreview,
    renderRoomPlanPreview: RoomPlanPreview,
    ...props
  }) => {
    if (roomPlan) {
      return (
        <PropertyType.Provider name={propertyType}>
          <Property.Provider id={property}>
            <RoomPlan.Provider id={roomPlan}>
              <RoomPlanPreview />
            </RoomPlan.Provider>
          </Property.Provider>
        </PropertyType.Provider>
      )
    } else if (property) {
      return (
        <PropertyType.Provider name={propertyType}>
          <Property.Provider id={property}>
            <PropertyPreview />
          </Property.Provider>
        </PropertyType.Provider>
      )
    } else if (propertyType) {
      return (
        <PropertyType.Provider name={propertyType}>
          <Property.Provider id={property}>
            <PropertyTypePreview />
          </Property.Provider>
        </PropertyType.Provider>
      )
    } else {
      return <BuildingPreview />
    }
  },
)

/**
 */
export const Preview = (props) => (
  <FormName>
    {({ form }) => <ConnectedPreview {...props} form={form} />}
  </FormName>
)
