import { combineReducers } from 'redux'

import { reducers as planReducers } from './plan/reducers'
import { reducers as propertyTypeReducers } from './property_type/reducers'
import { reducers as propertyReducers } from './property/reducers'
import { reducers as roomPlanReducers } from './room_plan/reducers'
import { reducers as roomReducers } from './room/reducers'
import { reducers as colorSelectionReducers } from './color_selection/reducers'
import { reducers as optionReducers } from './option/reducers'

export const reducers = combineReducers({
  property: propertyReducers,
  roomPlan: roomPlanReducers,
  propertyType: propertyTypeReducers,
  plan: planReducers,
  room: roomReducers,
  colorSelection: colorSelectionReducers,
  option: optionReducers,
})
