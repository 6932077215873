import React from 'react'

import { navigate } from 'gatsby'

import * as FormComponents from 'modules/plan/components/Form'
import { SelectPropertyType } from './PropertyType'
import { SelectProperty } from './Property'
import { SelectRoomPlan } from './RoomPlan'
import { SelectColorSelection } from './ColorSelection'
import { Preview } from './Preview'

import * as styles from './styles.module.scss'

/**
 */
export const FormFields = () => {
  return (
    <div>
      <SelectPropertyType />
      <SelectProperty />
      <SelectRoomPlan />
      <SelectColorSelection />
    </div>
  )
}

export const Submit = FormComponents.Submit
