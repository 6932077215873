import React from 'react'

import { connect } from 'react-redux'

import * as selectors from '../../selectors'
import { Context } from './Context'

/**
 */
export const Provider = connect(
  // mapStateToProps
  (state, props) => ({
    option: props.option
      ? props.option
      : selectors.selectEntryById(state)(props.id),
  }),
)(
  // BaseComponent
  ({ option, children }) => (
    <Context.Provider value={{ option }}>{children}</Context.Provider>
  ),
)
