import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'
import { Provider as ReferenceProvider } from './Reference'
import { filterByPropertyType } from '../utils'

/**
 *
 */
export const Each = connect(
  // mapStateToProps
  (state) => ({
    roomPlans: selectors.selectEntries(state),
  }),
)(
  //Base
  ({ roomPlans, children, propertyType }) => {
    return roomPlans
      .filter(filterByPropertyType(propertyType))
      .map((roomPlan) => (
        <ReferenceProvider key={roomPlan.key} roomPlan={roomPlan}>
          {children(roomPlan)}
        </ReferenceProvider>
      ))
  },
)
