import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import numeral from 'numeral'
import * as DefaultStyles from './styles.module.scss'
import { Page as Error } from 'components/Error'

import { Context } from './Context'
import * as ImageHelper from 'utils/ImageHelper'
export { OptionGroups } from './OptionGroups'

/**
 */
const BaseProvider = ({ room, children }) => {
  if (!room) {
    return <Error>部屋が選択されていません。</Error>
  }

  return <Context.Provider value={{ room }}>{children}</Context.Provider>
}

/**
 */
export const Provider = BaseProvider

/**
 */
const DefaultTextRenderer = ({ children, ...props }) => (
  <span {...props}>{children}</span>
)

/**
 */
const DefaultNumberRenderer = ({
  children,
  styles = DefaultStyles,
  ...props
}) => {
  const [i, d] = numeral(children).format('0,0.00').split('.')
  return (
    <span {...props}>
      <span className={styles.Integer}>{i}</span>
      <span className={styles.Dot}>.</span>
      <span className={styles.Decimal}>{d}</span>
    </span>
  )
}

/**
 */
const DefaultImageRenderer = ({ src, ...props }) => (
  <img src={ImageHelper.resolve(src)} {...props} />
)
