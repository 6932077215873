import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../actions'
import * as selectors from '../selectors'

/**
 */
const DefaultLoading = () => <span>Loading PropertyTypes...</span>

/**
 */
const ReduxInitializer = connect(
  // mapStateToProps,
  (state) => ({
    isInitialized: selectors.selectIsInitialized(state),
  }),
  // mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        init: actions.init,
      },
      dispatch,
    ),
)(
  // BaseComponent
  class extends React.Component {
    componentDidMount = () => {
      const { data, isInitialized, init } = this.props

      if (!isInitialized) {
        // dispatch init
        init(data.roomPlans.nodes)
      }
    }

    render = () => {
      const {
        children,
        isInitialized,
        renderers: { Loading = DefaultLoading } = {},
      } = this.props

      if (!isInitialized) {
        return <Loading />
      }

      return <>{children}</>
    }
  },
)

/**
 * Initializer to load all plans from graphql and initialize redux
 */
export const Initializer = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query RoomPlansInitializerQuery {
      roomPlans: allRoomPlansYaml(sort: { fields: label }) {
        nodes {
          id
          label
          propertyType
          layout {
            text
            subtext
            image
          }
          vr {
            isEnabled
            message
            key
          }
          cost
          defaultRoom
          rooms
        }
      }
    }
  `)

  return (
    <ReduxInitializer data={data} {...props}>
      {children}
    </ReduxInitializer>
  )
}
