import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'
import { Provider as ReferenceProvider } from "./Reference"

export const ActiveProvider = connect(
  // mapStateToProps
  (state) => ({
    plan: selectors.selectActiveEntry(state),
  }),
)(
  //
  ({ plan, onEmpty, children, ...props }) => {
    if (!plan) {
      if (onEmpty) {
        onEmpty()
      }
      throw new Error('Selected plan is not existed')
    }

    return (
      <ReferenceProvider plan={plan} {...props}>
        {children}
      </ReferenceProvider>
    )
  },
)
