import React from 'react'

import { Initializer as BuildingInitializer } from 'modules/building/components/Initializer'
import { Initializer as RoomPlanInitializer } from 'modules/room_plan/components/Initializer'
import { Initializer as PropertyTypesInitializer } from 'modules/property_type/components'
import { Initializer as PropertyInitializer } from 'modules/property/components/Initializer'
import { Initializer as ColorSelectionInitializer } from 'modules/color_selection/components/Initializer'
import { Initializer as OptionInitializer } from 'modules/option/components/Initializer'
import { Initializer as RoomInitializer } from 'modules/room/components'
import { Initializer as PlanInitializer } from 'modules/plan/components/Initializer'

import { Page as Loading } from 'components/Loading'

/**
 */
export const DataInitializer = ({ children }) => (
  <BuildingInitializer>
    <PropertyTypesInitializer
      renderers={{
        Loading: () => <Loading>物件種別情報を読み込み中です...</Loading>,
      }}
    >
      <PropertyInitializer
        renderers={{
          Loading: () => <Loading>物件情報を読み込み中です...</Loading>,
        }}
      >
        <RoomPlanInitializer
          renderers={{
            Loading: () => <Loading>ルームプランを読み込み中です...</Loading>,
          }}
        >
          <RoomInitializer>
            <ColorSelectionInitializer
              renderers={{
                Loading: () => (
                  <Loading>カラーバリエーションを読み込み中です...</Loading>
                ),
              }}
            >
              <OptionInitializer
                renderers={{
                  Loading: () => (
                    <Loading>オプション情報を読み込み中です...</Loading>
                  ),
                }}
              >
                <PlanInitializer>
                  {children}
                </PlanInitializer>
              </OptionInitializer>
            </ColorSelectionInitializer>
          </RoomInitializer>
        </RoomPlanInitializer>
      </PropertyInitializer>
    </PropertyTypesInitializer>
  </BuildingInitializer>
)
