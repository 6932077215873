import React from 'react'

import * as DefaultStyles from './default.module.scss'

import { StyleProvider, StyleConsumer } from './StyleContext'

export * from './StyleContext'

/**
 *
 */
export const Container = ({ className, children, styles = DefaultStyles }) => (
  <StyleProvider styles={styles}>
    <div className={className ? className : styles.Container}>{children}</div>
  </StyleProvider>
)

/**
 *
 */
export const Header = ({ className, children }) => (
  <StyleConsumer>
    {(styles) => (
      <div className={className ? className : styles.Header}>{children}</div>
    )}
  </StyleConsumer>
)

/**
 *
 */
export const Body = ({ className, children }) => (
  <StyleConsumer>
    {(styles) => (
      <div className={className ? className : styles.Body}>{children}</div>
    )}
  </StyleConsumer>
)

/**
 *
 */
export const Footer = ({ className, children }) => (
  <StyleConsumer>
    {(styles) => (
      <div className={className ? className : styles.Footer}>{children}</div>
    )}
  </StyleConsumer>
)
