import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../selectors'
import { Provider as ReferenceProvider } from './Reference'

/**
 */

/**
 */
export const Each = connect((state) => ({
  options: selectors.selectEntries(state),
}))(
  // BaseComponent
  ({ options, children, filter = () => true }) => {
    return options.filter(filter).map((option) => {
      return (
        <ReferenceProvider option={option} key={option.id}>
          {children(option)}
        </ReferenceProvider>
      )
    })
  },
)
