import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// Component imports

import * as actions from '../actions'
import * as selectors from '../selectors'

const DefaultLoading = () => <span>Loading PropertyTypes...</span>

/**
 */
const ReduxInitializer = connect(
  // mapStateToProps,
  (state) => ({
    isInitialized: selectors.selectIsInitialized(state),
  }),
  // mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        init: actions.init,
      },
      dispatch,
    ),
)(
  // BaseReduxInitializer
  class extends React.Component {
    componentDidMount = () => {
      const { init, data, isInitialized } = this.props

      if (!isInitialized) {
        // dispatch init
        init(data)
      }
    }

    render = () => {
      const {
        children,
        isInitialized,
        renderers: { Loading = DefaultLoading } = {},
      } = this.props

      if (!isInitialized) {
        return <Loading />
      }

      return children
    }
  },
)

/**
 * Connected Initializer
 */
export const Initializer = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query optionsInitializerQuery {
      allOptionsYaml {
        nodes {
          colorSelection
          defaultValue
          values {
            cost {
              fixed
              unit
            }
            description
            image
            label
            productCode
            url
            value
            vrValue
          }
          url
          isSupportVr
          vrPrefix
          vrWeight
          type
          label
          isEnabled
          id
          description
        }
      }
    }
  `)

  return (
    <ReduxInitializer data={data.allOptionsYaml.nodes} {...props}>
      {children}
    </ReduxInitializer>
  )
}
