import React from 'react'
//
import moment from 'moment'
//
import { StyleConsumer } from './Styles'

const DefaultOptionFormatter = (v) => v

export const CheckBox = ({
  className,
  name,
  input,
  label,
  placeholder,
  disabled,
  ...props
}) => (
  <StyleConsumer>
    {(styles) => (
      <label className={styles.Label_CheckBox}>
        <input
          type="checkbox"
          name={name}
          className={className ? className : styles.CheckBox_Input}
          placeholder={placeholder}
          disabled={disabled}
          {...input}
        />
        {label}
      </label>
    )}
  </StyleConsumer>
)

export const Hidden = ({
  className,
  disabled,
  name,
  input,
  placeholder,
  maxLength,
}) => (
  <StyleConsumer>
    {(styles) => (
      <input
        type="hidden"
        name={name}
        className={className ? className : styles.Hidden_Input}
        disabled={disabled}
        maxlenght={maxLength}
        {...input}
      />
    )}
  </StyleConsumer>
)

export const Text = ({
  className,
  disabled,
  name,
  input,
  placeholder,
  maxLength,
}) => (
  <StyleConsumer>
    {(styles) => (
      <input
        type="text"
        name={name}
        className={className ? className : styles.Text_Input}
        placeholder={placeholder}
        disabled={disabled}
        maxlenght={maxLength}
        {...input}
      />
    )}
  </StyleConsumer>
)

export const Numeric = ({
  className,
  disabled,
  name,
  input,
  placeholder,
  maxLength,
  pattern = 'd*',
}) => (
  <StyleConsumer>
    {(styles) => (
      <input
        type="text"
        pattern={pattern}
        name={name}
        className={className ? className : styles.Numeric_Input}
        placeholder={placeholder}
        disabled={disabled}
        maxlenght={maxLength}
        {...input}
      />
    )}
  </StyleConsumer>
)

export const Password = ({
  className,
  disabled,
  name,
  input,
  placeholder,
  maxLength,
}) => (
  <StyleConsumer>
    {(styles) => (
      <input
        type="password"
        name={name}
        className={className ? className : styles.Password_Input}
        placeholder={placeholder}
        disabled={disabled}
        maxlenght={maxLength}
        {...input}
      />
    )}
  </StyleConsumer>
)

export const Email = ({
  className,
  disabled,
  name,
  input,
  placeholder,
  ...rem
}) => (
  <StyleConsumer>
    {(styles) => (
      <input
        type="email"
        name={name}
        className={className ? className : styles.Email_Input}
        placeholder={placeholder}
        disabled={disabled}
        value={input.value}
        {...input}
      />
    )}
  </StyleConsumer>
)

export const Option = ({ className, value, label }) => (
  <StyleConsumer>
    {(styles) => (
      <option
        value={value}
        className={className ? className : styles.Select_Option}
      >
        {label}
      </option>
    )}
  </StyleConsumer>
)

export const Select = ({
  className,
  disabled,
  name,
  input,
  placeholder,
  options,
  optionFormatter = DefaultOptionFormatter,
  renderOption: OptionRenderer = Option,
  ...props
}) => {
  const optionNodes = options
    .map(optionFormatter)
    .map(({ value, label }) => (
      <OptionRenderer key={value} value={value} label={label} />
    ))

  return (
    <StyleConsumer>
      {(styles) => (
        <select
          name={name}
          className={className ? className : styles.Select}
          placeholder={placeholder}
          disabled={disabled}
          {...input}
          {...props}
        >
          {optionNodes}
        </select>
      )}
    </StyleConsumer>
  )
}

export const SelectMonth = ({
  className,
  disabled,
  name,
  input,
  placeholder,
  formatOption = DefaultOptionFormatter,
  renderOption: OptionRenderer = Option,
}) => {
  const optionNodes = [...Array(12).keys()]
    .map((v) => v + 1)
    .map((v) => ({ value: v, label: v.toString() }))
    .map(formatOption)
    .map(({ value, label }) => (
      <OptionRenderer key={value} value={value} label={label} />
    ))

  return (
    <StyleConsumer>
      {(styles) => (
        <select
          name={name}
          className={className ? className : styles.Select}
          placeholder={placeholder}
          disabled={disabled}
          {...input}
        >
          {optionNodes}
        </select>
      )}
    </StyleConsumer>
  )
}

export const SelectYear = ({
  className,
  disabled,
  name,
  input,
  placeholder,
  formatOption = DefaultOptionFormatter,
  renderOption: OptionRenderer = Option,
  since,
  until,
}) => {
  const thisYear = moment().year()

  const optionNodes = [...Array(20).keys()]
    .map((v) => thisYear - 5 + v)
    .map((v) => ({ value: v, label: v.toString() }))
    .map(formatOption)
    .map(({ value, label }) => (
      <OptionRenderer key={value} value={value} label={label} />
    ))

  return (
    <StyleConsumer>
      {(styles) => (
        <select
          name={name}
          className={className ? className : styles.Select}
          placeholder={placeholder}
          disabled={disabled}
          {...input}
        >
          {optionNodes}
        </select>
      )}
    </StyleConsumer>
  )
}

export const Choice = ({
  id,
  checked,
  value,
  input = {},
  label,
  styles: overwriteStyles,
  onChange = () => {},
  ...props
}) => (
  <StyleConsumer styles={overwriteStyles}>
    {(styles) => (
      <label
        className={checked ? styles.Choice_Label_Checked : styles.Choice_Label}
      >
        <input
          id={id}
          className={styles.Choice_Input}
          type="radio"
          {...input}
          value={`${value}`}
          checked={checked}
          onChange={input.onChange ? input.onChange : onChange}
        />
        {label}
      </label>
    )}
  </StyleConsumer>
)

export const Choices = ({
  input = {},
  options,
  defaultValue = null,
  styles: overwriteStyles,
  renderChoice: RenderChoice = Choice,
  value = null,
  ...props
}) => {
  return (
    <StyleConsumer styles={overwriteStyles}>
      {(styles) => (
        <div className={styles.Choices}>
          {options.map((option) => (
            <RenderChoice
              key={option.value}
              checked={option.value === (input.value ? input.value : value)}
              {...props}
              {...option}
              styles={styles}
              input={{
                ...input,
              }}
              value={option.value}
            />
          ))}
        </div>
      )}
    </StyleConsumer>
  )
}
